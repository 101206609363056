import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import TopLoadingBar from 'react-top-loading-bar';
import FilterByMonth from './FilterByMonth';
import { useNavigate } from 'react-router-dom';
import './Occurrence.css';
import ThumbnailCard from './ThumbnailCard';
import { Card, Checkbox, Avatar, Row, Col, Typography } from 'antd';
import { API_ENDPOINTS } from '../../config/apiConfig';


const { Text } = Typography;

const Occurrences = () => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [occurrences, setOccurrences] = useState([]);
  const [isGroupingEnabled, setIsGroupingEnabled] = useState(false);
  const [selectedOccurrences, setSelectedOccurrences] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [lastQuery, setLastQuery] = useState(null);
  const navigate = useNavigate();

  const loadingBarRef = useRef(null);

  useEffect(() => {
    const fetchCurrentDate = async () => {
      try {
        const response = await fetch(`${API_ENDPOINTS.current_date}`);
        if (!response.ok) {
          throw new Error('Erro ao buscar a data atual');
        }
        const data = await response.json();
        const currentDate = new Date(data.current_date);
        setCurrentMonth(currentDate.getMonth());
        setCurrentYear(currentDate.getFullYear());

        // Busca as ocorrências para o mês corrente
        const start_date = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-01`;
        const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        const end_date = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(lastDay).padStart(2, '0')}`;

        fetchOccurrences(start_date, end_date);
      } catch (error) {
        console.error('Erro ao buscar a data atual:', error);
      }
    };

    fetchCurrentDate();
  }, []);


  useEffect(() => {
    const storedQuery = JSON.parse(localStorage.getItem('lastQuery'));
    if (storedQuery && storedQuery.start_date && storedQuery.end_date) {
      setLastQuery(storedQuery);
      fetchOccurrences(storedQuery.start_date, storedQuery.end_date);
    }
  }, []);

  useEffect(() => {
    if (selectedMonth !== null && currentYear !== null) {
      const start_date = `${currentYear}-${String(selectedMonth + 1).padStart(2, '0')}-01`;
      const end_date = `${currentYear}-${String(selectedMonth + 2).padStart(2, '0')}-01`;

      fetchOccurrences(start_date, end_date);
    }
  }, [selectedMonth, currentYear]);

  const fetchOccurrences = async (start_date, end_date) => {
    try {
      loadingBarRef.current?.continuousStart();
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.occurrence}/?start_date=${start_date}&end_date=${end_date}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 403) {
        localStorage.removeItem('token');
        navigate('/login');
        return;
      }

      if (!response.ok) {
        throw new Error('Erro ao buscar dados');
      }
      const data = await response.json();
      setOccurrences(data);

      // Armazena a última consulta no localStorage
      localStorage.setItem('lastQuery', JSON.stringify({ start_date, end_date }));
      setLastQuery({ start_date, end_date });
    } catch (error) {
      console.error('Erro ao buscar ocorrências:', error);
    } finally {
      loadingBarRef.current?.complete();
    }
  };

  const handleMonthClick = (index) => {
    setSelectedMonth(index);
  };

  const handleDayClick = (start_date, end_date) => {
    if (start_date && end_date) {
      fetchOccurrences(start_date, end_date);
    } else if (selectedMonth !== null && currentYear !== null) {
      // Refaz a consulta para o mês inteiro se o dia for desmarcado
      const start_date = `${currentYear}-${String(selectedMonth + 1).padStart(2, '0')}-01`;
      const end_date = `${currentYear}-${String(selectedMonth + 2).padStart(2, '0')}-01`;
      fetchOccurrences(start_date, end_date);
    }
  };

  const handleGroupingChange = (event) => {
    setIsGroupingEnabled(event.target.checked);
    setSelectedOccurrences([]);
  };

  const handleOccurrenceSelection = (groupId) => {
    setSelectedOccurrences((prevSelected) =>
      prevSelected.includes(groupId)
        ? prevSelected.filter((id) => id !== groupId)
        : [...prevSelected, groupId]
    );
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short' };
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR', options).replace('.', '');
  };

  return (
    <div>
      <TopLoadingBar
        color="#f11946"
        height={3}
        ref={loadingBarRef}
      />
      <div className='FilterByMonth'>
        <div className='container'>
          <FilterByMonth
            currentMonth={currentMonth}
            onMonthClick={handleMonthClick}
            onDayClick={handleDayClick}
          />
        </div>
      </div>

      <div className='container occurrences'>
        <div className='row'>
          {occurrences.length > 0 ? (
            occurrences.map((occurrence) => (
              <div key={occurrence.group_id} className="col-lg-12" style={{ marginBottom: '16px' }}>
                <Link
                  to={`/occurrences/detail/${occurrence.group_id}`}
                  state={{ from: 'occurrences', occurrences }}
                  style={{ textDecoration: 'none' }}
                >
                  <Card
                    style={{ marginBottom: '16px' }}
                    actions={
                      isGroupingEnabled && [
                        <Checkbox
                          key={`checkbox-${occurrence.group_id}`}
                          checked={selectedOccurrences.includes(occurrence.group_id)}
                          onChange={() => handleOccurrenceSelection(occurrence.group_id)}
                        />
                      ]
                    }
                  >
                    <Row gutter={16}>
                      <Col flex="none">
                        <Avatar shape="square" size={64} src={`${API_ENDPOINTS.images}/thumb/${occurrence.company.image}`} />
                      </Col>
                      <Col flex="auto">
                        <Text type="strong">{occurrence.company.name} | {formatDate(occurrence.created_at)}</Text>
                        <p>{occurrence.title}</p>
                        <p>
                          {[
                            occurrence.composition.audios > 0 && `${occurrence.composition.audios} ${occurrence.composition.audios > 1 ? 'áudios' : 'áudio'}`,
                            occurrence.composition.images > 0 && `${occurrence.composition.images} ${occurrence.composition.images > 1 ? 'imagens' : 'imagem'}`,
                            occurrence.composition.messages > 0 && `${occurrence.composition.messages} ${occurrence.composition.messages > 1 ? 'mensagens' : 'mensagem'}`,
                            occurrence.composition.videos > 0 && `${occurrence.composition.videos} ${occurrence.composition.videos > 1 ? 'vídeos' : 'vídeo'}`
                          ].filter(Boolean).join(' e ')}
                        </p>
                      </Col>
                      <Col span={8}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                          {occurrence.images && occurrence.images.length > 0 && (
                            <ThumbnailCard images={occurrence.images} />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Link>
              </div>
            ))
          ) : (
            <div className='col-12'>
              <p>Nenhuma ocorrência encontrada para o período selecionado.</p>
            </div>
          )}
        </div>
      </div>

      {lastQuery && (
        <div style={{ display: 'none' }}>
          <h3>Última Consulta:</h3>
          <p><strong>Data de Início:</strong> {lastQuery.start_date}</p>
          <p><strong>Data de Fim:</strong> {lastQuery.end_date}</p>
        </div>
      )}
    </div>
  );
};

export default Occurrences;
