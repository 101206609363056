import React, { useEffect } from 'react';
import { Modal, Form, Input, Button, Checkbox } from 'antd';
import { API_ENDPOINTS } from '../../../config/apiConfig';

const EditContractModal = ({ visible, onClose, contract, onContractUpdated }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (contract) {
      form.setFieldsValue({
        title: contract.title,
        contract_number: contract.contract_number,
        description: contract.description,
        technical_responsible: contract.technical_responsible,
        active: contract.active,
      });
    }
  }, [contract, form]);

  const handleSubmit = async (values) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.contracts}/${contract.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: values.title,
          contract_number: values.contract_number,
          description: values.description,
          technical_responsible: values.technical_responsible,
          active: values.active,
        }),
      });

      if (response.ok) {
        onContractUpdated();
        onClose();
        form.resetFields();
      } else {
        console.error('Erro ao atualizar contrato:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao atualizar contrato:', error);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Editar Contrato"
      onCancel={onClose}
      footer={null}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item name="title" label="Título" rules={[{ required: true, message: 'Por favor, insira o título!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="contract_number" label="Número do Contrato" rules={[{ required: true, message: 'Por favor, insira o número do contrato!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Descrição" rules={[{ required: true, message: 'Por favor, insira uma descrição!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="technical_responsible" label="Responsável Técnico" rules={[{ required: true, message: 'Por favor, insira o responsável técnico!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="active" valuePropName="checked">
          <Checkbox>Ativo</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Salvar</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditContractModal;
