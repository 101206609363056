import React, { useState } from 'react';
import { Modal, Form, Input, Checkbox, Button } from 'antd';
import { API_ENDPOINTS } from '../../config/apiConfig';


export default function AddReferenceModal({ visible, onCancel, reloadReferences, referenceType }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const endpoints = {
    status: `${API_ENDPOINTS.status}/`, 
    places: `${API_ENDPOINTS.places}/`, 
    criticalities: `${API_ENDPOINTS.criticalities}/`,
    categories: `${API_ENDPOINTS.categories}/`,
  };

  const handleAdd = async () => {
    try {
      const token = localStorage.getItem('token');
      const values = await form.validateFields();
      setLoading(true);

      const response = await fetch(endpoints[referenceType], {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: values.name,
          description: values.description,
          active: values.active,
          company_id: 1, // Ajuste conforme necessário
        }),
      });

      if (response.ok) {
        reloadReferences(); // Recarrega a lista de referências
        onCancel(); // Fecha o modal
        form.resetFields();
      } else {
        console.error('Erro ao adicionar referência:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao adicionar referência:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Adicionar Nova Referência"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleAdd}>
          Adicionar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[{ required: true, message: 'Por favor, insira o nome' }]}
        >
          <Input autoFocus />
        </Form.Item>
        <Form.Item
          label="Descrição"
          name="description"
          rules={[{ required: true, message: 'Por favor, insira a descrição' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="active" valuePropName="checked" initialValue={true}>
          <Checkbox>Ativo</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}
