import React, { useState, useEffect } from 'react';
import { Drawer, Table, Avatar, Spin, Button, Checkbox, Typography } from 'antd';
import { API_ENDPOINTS } from '../../../config/apiConfig';


const { Text } = Typography;

const AddAgentsDrawer = ({ visible, onClose, onAdd, company }) => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAgents, setSelectedAgents] = useState([]);

  useEffect(() => {
    if (visible) {
      fetchUnlinkedAgents();
    }
  }, [visible]);

  const fetchUnlinkedAgents = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.agents}/?company_id=0`, { 

        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setAgents(data);
      } else {
        console.error('Erro ao buscar agentes:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao buscar agentes:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (agent, checked) => {
    setSelectedAgents(prev => {
      if (checked) {
        return [...prev, agent]; 
      } else {
        return prev.filter(a => a.id !== agent.id); 
      }
    });
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedAgents(agents);
    } else {
      setSelectedAgents([]);
    }
  };

  const columns = [
    {
      title: (
        <Checkbox
          onChange={(e) => handleSelectAll(e.target.checked)}
          checked={selectedAgents.length === agents.length && agents.length > 0}
          indeterminate={selectedAgents.length > 0 && selectedAgents.length < agents.length}
        />
      ),
      dataIndex: 'select',
      key: 'select',
      render: (_, agent) => (
        <Checkbox
          onChange={(e) => handleCheckboxChange(agent, e.target.checked)}
          checked={selectedAgents.some(a => a.id === agent.id)}
        />
      ),
    },
    {
      title: 'Agente',
      dataIndex: 'name',
      key: 'name',
      render: (_, agent) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar style={{ backgroundColor: '#87d068', marginRight: 8 }}>
            {agent.name[0]}
          </Avatar>
          {agent.name}
        </div>
      ),
    },
  ];

  const handleAddSelectedAgents = async () => {
    const token = localStorage.getItem('token');
    const company_id = company?.id; // Uso de optional chaining

    if (!company_id) {
      console.error('ID da empresa não disponível');
      return;
    }

    const agentsToUpdate = selectedAgents.map(agent => ({
      id: agent.id,
      company_id,
    }));

    try {
      const response = await fetch(`${API_ENDPOINTS.agents}/batch-update`, { 
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(agentsToUpdate),
      });

      if (response.ok) {
        selectedAgents.forEach(agent => onAdd(agent));
        setSelectedAgents([]); 
        onClose(); 
      } else {
        console.error('Erro ao atualizar agentes:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao atualizar agentes:', error);
    }
  };

  return (
    <Drawer
      title="Adicionar Agentes"
      placement="right"
      onClose={onClose}
      visible={visible}
      width={400}
      footer={
        selectedAgents.length > 0 && (
          <div style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={handleAddSelectedAgents}>
              Adicionar
            </Button>
          </div>
        )
      }
      footerStyle={{ position: 'sticky', bottom: 0 }}
    >
      <div>
        {/* Exibe o ID da empresa */}
        <Text strong>Empresa ID: </Text>
        <Text>{company?.id ?? 'ID da empresa não disponível'}</Text>
      </div>
      <div style={{ margin: '16px 0' }}>
        {/* Exibe os IDs dos agentes selecionados */}
        <Text strong>IDs dos Agentes Selecionados: </Text>
        <Text>{selectedAgents.map(agent => agent.id).join(', ') || 'Nenhum agente selecionado'}</Text>
      </div>
      {loading ? (
        <Spin tip="Carregando agentes..." />
      ) : (
        <Table
          size='small'
          columns={columns}
          dataSource={agents}
          rowKey="id"
          pagination={false} 
        />
      )}
    </Drawer>
  );
};

export default AddAgentsDrawer;
