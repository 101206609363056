// src/pages/streams/Streams.js
import React from 'react';
import { Table, Typography, Row, Col, Card, Breadcrumb } from 'antd';

const { Title } = Typography;

const Streams = () => {
  const data = [
    { id: '1', name: 'Categorização de Ocorrências', type: 'Optativo', status: 'Ativo', description: 'Este fluxo permite ao agente realizar a categorização de ocorrências de forma eficiente. O agente pode selecionar entre diferentes categorias para classificar cada ocorrência, ajudando a organizar e gerenciar as informações de maneira mais estruturada.' },
    { id: '2', name: 'Categorização por palavra chave', type: 'Automático', status: 'Ativo', description: 'Este fluxo permite ao agente realizar a categorização de ocorrências de forma eficiente. O agente pode selecionar entre diferentes categorias para classificar cada ocorrência, ajudando a organizar e gerenciar as informações de maneira mais estruturada.' },
    { id: '3', name: 'Categorização por palavra chave', type: 'Optativo', status: 'Ativo', description: 'Este fluxo permite ao agente realizar a categorização de ocorrências de forma eficiente. O agente pode selecionar entre diferentes categorias para classificar cada ocorrência, ajudando a organizar e gerenciar as informações de maneira mais estruturada.' },
    { id: '4', name: 'Definição de localidade', type: 'Optativo', status: 'Ativo', description: 'Este fluxo permite ao agente realizar a categorização de ocorrências de forma eficiente. O agente pode selecionar entre diferentes categorias para classificar cada ocorrência, ajudando a organizar e gerenciar as informações de maneira mais estruturada.' },
  ];

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Ação',
      key: 'action',
      render: (_, record) => (
        <a href={`/streams/detail/${record.id}`}>Ver Detalhes</a>
      ),
    },
  ];

  return (
    <Row justify="center" align="middle" style={{ padding: '10px' }}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Breadcrumb style={{ marginBottom: 16 }}>
          <Breadcrumb.Item>Fluxos Conversacionais</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Table dataSource={data} columns={columns} rowKey="id" />
        </Card>
      </Col>
    </Row>
  );
};

export default Streams;
