import React, { useState, useEffect } from 'react';
import { Button, Table, Space, Breadcrumb, Row, Col, Card, Spin } from 'antd';
import { useNavigate } from 'react-router-dom'; 
import AddAgentModal from './AddAgentModal';
import EditAgentModal from './EditAgentModal';
import DeleteModal from '../../../components/DeleteModal';
import { API_ENDPOINTS } from '../../../config/apiConfig';

const AgentListPage = () => {
  const [agents, setAgents] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.agents}/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 403) {
        localStorage.removeItem('token');
        navigate('/login'); 
        return;
      }

      if (response.ok) {
        const data = await response.json();
        setAgents(data);
      } else {
        console.error('Erro ao buscar agentes:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao buscar agentes:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (agent) => {
    setSelectedAgent(agent);
    setShowEditModal(true);
  };

  const handleDeleteClick = (agent) => {
    setSelectedAgent(agent);
    setShowDeleteModal(true);
  };

  const handleAgentUpdated = () => {
    fetchAgents();
    setShowEditModal(false);
    setShowDeleteModal(false);
  };

  const columns = [
    { title: 'Nome', dataIndex: 'name', key: 'name' },
    { title: 'Origem', dataIndex: 'origin', key: 'origin' },
    { title: 'ID de Origem', dataIndex: 'origin_id', key: 'origin_id' },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, agent) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleEditClick(agent)}>Editar</Button>
          <Button type="link" danger onClick={() => handleDeleteClick(agent)}>Excluir</Button>
        </Space>
      ),
    },
  ];

  return (
    <div className='container'>
      <Row justify="left" align="middle" style={{ padding: '10px' }}>
        <Breadcrumb style={{ marginBottom: 16 }}>
          <Breadcrumb.Item>Gestão</Breadcrumb.Item>
          <Breadcrumb.Item>Administração</Breadcrumb.Item>
          <Breadcrumb.Item>Agentes</Breadcrumb.Item>
        </Breadcrumb>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <Button type="primary" onClick={() => setShowAddModal(true)}>Adicionar Agente</Button>
            {loading ? (
              <Spin size="large" />
            ) : (
              <Table size='small' pagination={false} dataSource={agents} columns={columns} rowKey="id" style={{ marginTop: 16 }} />
            )}
            <AddAgentModal
              visible={showAddModal}
              onCancel={() => setShowAddModal(false)}
              reloadAgents={fetchAgents}
            />
            <EditAgentModal
              visible={showEditModal}
              onCancel={() => setShowEditModal(false)}
              agent={selectedAgent}
              handleAgentUpdated={handleAgentUpdated}
            />
            <DeleteModal
              visible={showDeleteModal}
              onCancel={() => setShowDeleteModal(false)}
              apiUrl={`${API_ENDPOINTS.agents}`}
              itemId={selectedAgent?.id}
              itemName={`o agente ${selectedAgent?.name}`}
              onItemDeleted={handleAgentUpdated}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AgentListPage;