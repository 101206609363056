import React, { useState } from 'react';
import { Card, Col, Row, Descriptions, Typography, DatePicker, ConfigProvider, Avatar } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import locale from 'antd/lib/locale/pt_BR';
import { API_ENDPOINTS } from '../../../config/apiConfig';
const { Title, Paragraph } = Typography;


const OccurrenceInfo = ({ occurrenceData }) => {
    // Formatando a data para manter apenas ano, mês e dia
    const [title, setTitle] = useState(occurrenceData.title || 'Ocorrência sem título');
    const [details, setDetails] = useState(occurrenceData.occurrence_details.text || '');
    const [submissionDate, setSubmissionDate] = useState(
        dayjs(occurrenceData.submission.date).format('YYYY-MM-DD') // Formatar a data
    );

    const handleDateChange = (date, dateString) => {
        console.log('Data selecionada:', dateString); // Verificar a nova data no console
        setSubmissionDate(date); // Atualizar a data selecionada
    };

    return (
        <ConfigProvider locale={locale}>
            <Card>
                <Row gutter={24} align="top">
                    <Col style={{ flex: '0 0 auto' }}>
                        <Avatar shape="square" size={64} src={`${API_ENDPOINTS.images}/thumb/${occurrenceData.company.image}`} />
                    </Col>

                    <Col flex="1">
                        <div className='company-name'>
                            {occurrenceData.company.name}
                        </div>

                        <Title>
                            <Typography.Title level={4}
                                editable={{
                                    onChange: setTitle,
                                    triggerType: ['icon'],
                                }}
                            >
                                {title}
                            </Typography.Title>
                        </Title>
                        <Paragraph
                            editable={{
                                onChange: setDetails,
                                triggerType: ['icon'],
                            }}
                        >
                            {details}
                        </Paragraph>

                        <p> {occurrenceData.composition.messages} Mensagens | {occurrenceData.composition.audios} Áudios | {occurrenceData.composition.images} Imagens | {occurrenceData.composition.videos} Vídeos</p>



                    </Col>

                    <Col style={{ flex: '0 0 auto' }}>
                        <DatePicker
                            value={dayjs(submissionDate)}
                            onChange={handleDateChange}
                            format="DD/MM/YYYY"
                            locale="pt-br"
                        />
                    </Col>
                </Row>
            </Card>

        </ConfigProvider>
    );
};

export default OccurrenceInfo;
