import React, { useState } from 'react';
import { Layout, Menu, Button, Switch, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { BulbOutlined, BulbFilled } from '@ant-design/icons';
import './Header.css';

const { Header: AntHeader } = Layout;
const { Search } = Input;

const Header = ({ isDarkMode, toggleTheme }) => {
  const [current, setCurrent] = useState('home');
  const navigate = useNavigate();

  const onClick = (e) => {
    setCurrent(e.key);
  };

  const onSearch = (value) => {
    navigate(`/search-results?q=${encodeURIComponent(value)}`);
  };

  const items = [
    {
      label: <Link to="/">Home</Link>,
      key: 'home',
    },
    {
      label: <Link to="/streams">Fluxos Conversacionais</Link>,
      key: 'streams',
    },
    {
      label: <Link to="/occurrences">Ocorrências</Link>,
      key: 'occurrences',
    },
    {
      label: 'Gestão',
      key: 'management',
      children: [
        {
          type: 'group',
          label: 'OPERAÇÃO',
          children: [
            {
              label: <Link to="/references/categories">Categorias</Link>,
              key: 'operation-categories',
            },
            {
              label: <Link to="/references/status">Status</Link>,
              key: 'operation-status',
            },
            {
              label: <Link to="/references/places">Localidades</Link>,
              key: 'operation-places',
            },
            {
              label: <Link to="/references/criticalities">Criticidades</Link>,
              key: 'operation-criticalities',
            },
          ],
        },
        {
          type: 'group',
          label: 'ADMINISTRAÇÃO',
          children: [
            {
              label: <Link to="/management/users">Usuários</Link>,
              key: 'management-users',
            },
            {
              label: <Link to="/management/agents">Agentes</Link>,
              key: 'management-agents',
            },
            {
              label: <Link to="/management/companies">Empresas</Link>,
              key: 'management-companies',
            },
            {
              label: <Link to="/management/contracts">Contratos</Link>,
              key: 'management-contracts',
            },
          ],
        },
      ],
    },
  ];

  return (
    <AntHeader theme="dark" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div className="logo">
        <img className="clientBrand" src="/otalk.png" alt="Logo" />
      </div>

      <Menu
        theme="dark"
        mode="horizontal"
        onClick={onClick}
        selectedKeys={[current]}
        items={items}
      />

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Search
          placeholder="Buscar..."
          onSearch={onSearch}
          style={{ marginRight: 20, width: 200 }}
        />
        <Switch
          checked={isDarkMode}
          checkedChildren={<BulbFilled />}
          unCheckedChildren={<BulbOutlined />}
          onChange={toggleTheme}
          style={{ marginRight: 20 }}
        />
        <Button type="primary">
          BM
        </Button>
      </div>
    </AntHeader>
  );
};

export default Header;
