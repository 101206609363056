import React from 'react';
import { Card } from 'antd';
import { ResponsiveStream } from '@nivo/stream';



// Lista de rótulos para semanas
const weeks = ['11/Ago -  17/Ago', '18/Ago - 24/Ago', '25/Ago - 31/Ago', '1/Set - 7/Set', '8/Set - 14/Set'];

const StreamChart = ({ data }) => (
    <Card bordered={false}>
        <div style={{ height: '300px' }}>
            <ResponsiveStream
                data={data}
                keys={['MAX ENGENHARIA', 'ROTA ENGENHARIA', 'ENGEVIX', 'ENGNES', 'ROTACIONAL ENGENHARIA LTDA']}
                margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 9,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: weeks.map((week, index) => index), 
                    format: (value) => weeks[value] || '', 
                    legendOffset: 36
                }}
                axisLeft={{
                    orient: 'top',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: -40
                }}
                enableGridX={true}
                enableGridY={false}
                colors={{ scheme: 'nivo' }}
                fillOpacity={1}
                borderColor={{ theme: 'background' }}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: '#2c998f',
                        size: 4,
                        padding: 2,
                        stagger: true
                    },
                    {
                        id: 'squares',
                        type: 'patternSquares',
                        background: 'inherit',
                        color: '#e4c912',
                        size: 6,
                        padding: 2,
                        stagger: true
                    }
                ]}
                dotSize={8}
                dotColor={{ from: 'color' }}
                dotBorderWidth={2}
                dotBorderColor={{ from: 'color', modifiers: [['darker', 0.7]] }}
            />
        </div>
    </Card>
);

export default StreamChart;