// OccurrenceMessages.js
import React from 'react';
import { Avatar, Row, Col } from 'antd';

import './OccurrenceMessages.css';

const OccurrenceMessages = ({ messages }) => {
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
    };

    // Retorna null (não renderiza nada) se não houver mensagens
    if (!messages || messages.length === 0) {
        return null; 
    }

    return (
        <div>
            {messages.map((messageObj, index) => (
                // Verifica se há mensagens para exibir
                messageObj.messages.length > 0 ? (
                    <div key={index} className="message-container">
                        <h3>Mensagens</h3>
                        <Row gutter={16}>
                            <Col>
                                <Avatar style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }} size="large">
                                    {messageObj.User.charAt(0).toUpperCase()}
                                </Avatar>
                            </Col>
                            <Col span={22}>
                                <span className='user'>{messageObj.User} - {formatDate(messageObj.timestamp)}</span>
                                {messageObj.messages.map((msg, idx) => (
                                    <span className="msg-icn" key={idx}>
                                        <p>{msg.text}</p>
                                    </span>
                                ))}
                            </Col>
                        </Row>
                    </div>
                ) : null
            ))}
        </div>
    );
};

export default OccurrenceMessages;
