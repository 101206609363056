import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../components/ProtectedRoute';
import Layout from '../components/Layout';
import {
  Dashboard, Occurrences, Streams, StreamDetail, OccurrenceDetail,
  Login, ReferenceListPage, UserListPage, ContractListPage,
  CompanyListPage, AgentListPage, MessageListPage,
  ImagesListPage, ImageDetail, SearchResults
} from '../pages';

const AppRoutes = ({ isDarkMode, toggleTheme }) => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/" element={<ProtectedRoute element={<Layout isDarkMode={isDarkMode} toggleTheme={toggleTheme}><Dashboard /></Layout>} />} />
    <Route path="/dashboard" element={<ProtectedRoute element={<Layout isDarkMode={isDarkMode} toggleTheme={toggleTheme}><Dashboard /></Layout>} />} />
    <Route path="/occurrences" element={<ProtectedRoute element={<Layout isDarkMode={isDarkMode} toggleTheme={toggleTheme}><Occurrences /></Layout>} />} />
    <Route path="/occurrences/detail/:id" element={<ProtectedRoute element={<Layout isDarkMode={isDarkMode} toggleTheme={toggleTheme}><OccurrenceDetail /></Layout>} />} />
    <Route path="/streams" element={<ProtectedRoute element={<Layout isDarkMode={isDarkMode} toggleTheme={toggleTheme}><Streams /></Layout>} />} />
    <Route path="/streams/detail/:id" element={<ProtectedRoute element={<Layout isDarkMode={isDarkMode} toggleTheme={toggleTheme}><StreamDetail /></Layout>} />} />
    <Route path="/references/:type" element={<ProtectedRoute element={<Layout isDarkMode={isDarkMode} toggleTheme={toggleTheme}><ReferenceListPage /></Layout>} />} />
    <Route path="/management/agents" element={<ProtectedRoute element={<Layout isDarkMode={isDarkMode} toggleTheme={toggleTheme}><AgentListPage /></Layout>} />} />
    <Route path="/management/users" element={<ProtectedRoute element={<Layout isDarkMode={isDarkMode} toggleTheme={toggleTheme}><UserListPage /></Layout>} />} />
    <Route path="/management/contracts" element={<ProtectedRoute element={<Layout isDarkMode={isDarkMode} toggleTheme={toggleTheme}><ContractListPage /></Layout>} />} />
    <Route path="/management/companies" element={<ProtectedRoute element={<Layout isDarkMode={isDarkMode} toggleTheme={toggleTheme}><CompanyListPage /></Layout>} />} />
    <Route path="/management/message" element={<ProtectedRoute element={<Layout isDarkMode={isDarkMode} toggleTheme={toggleTheme}><MessageListPage /></Layout>} />} />
    <Route path="/images" element={<ProtectedRoute element={<Layout isDarkMode={isDarkMode} toggleTheme={toggleTheme}><ImagesListPage /></Layout>} />} />
    <Route path="/images/detail/:id" element={<ProtectedRoute element={<Layout isDarkMode={isDarkMode} toggleTheme={toggleTheme}><ImageDetail /></Layout>} />} />
    <Route path="/search-results" element={<ProtectedRoute element={<Layout isDarkMode={isDarkMode} toggleTheme={toggleTheme}><SearchResults /></Layout>} />} />
  </Routes>
);

export default AppRoutes;
