import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Card, Breadcrumb, Image } from 'antd';
import { API_ENDPOINTS } from '../../config/apiConfig';
import { useNavigate } from 'react-router-dom'; 


const ImagesListPage = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.images_api}/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setImages(data);
      } else {
        console.error('Erro ao buscar imagens:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao buscar imagens:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='container'>
      <Row justify="left" align="middle" style={{ padding: '10px' }}>
        <Breadcrumb style={{ marginBottom: 16 }}>
          <Breadcrumb.Item>Gestão</Breadcrumb.Item>
          <Breadcrumb.Item>Imagens</Breadcrumb.Item>
        </Breadcrumb>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <Button type="primary" onClick={fetchImages} style={{ marginBottom: 20 }}>
              Recarregar Imagens
            </Button>
            <Row gutter={[16, 16]}>
              {loading ? (
                <p>Carregando imagens...</p>
              ) : (
                images.map((image) => (
                  <Col key={image.id} xs={24} sm={12} md={8} lg={6} xl={4}>
                    <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/images/detail/${image.id}`)}>
                      <Image
                        src={`${API_ENDPOINTS.images}/${image.file_path}`}
                        alt={image.file_path}
                        width={150}
                        height={150}
                        style={{ objectFit: 'cover' }}
                        preview={{
                          src: `${API_ENDPOINTS.images}/${image.file_path}`,
                        }}
                      />
                      <p>{image.sender}</p>
                    </div>

                  </Col>
                ))
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ImagesListPage;
