import React, { useState, useEffect } from 'react';
import { Row, Col, Timeline, Select, Button, Typography, Card, Breadcrumb } from 'antd';
import { ClockCircleOutlined, MenuOutlined, MessageOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

const { Option } = Select;
const { Title, Paragraph } = Typography;

const StreamDetail = () => {
  const { id } = useParams(); // Pode ser removido se não precisar do id
  const [categories, setCategories] = useState([
    { id: 1, name: 'Categoria 1' },
    { id: 2, name: 'Categoria 2' },
    { id: 3, name: 'Categoria 3' },
  ]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [stream, setStream] = useState({
    name: 'Categorização por Ocorrências',
    description: 'Este fluxo permite ao agente realizar a categorização de ocorrências de forma eficiente. O agente pode selecionar entre diferentes categorias para classificar cada ocorrência, ajudando a organizar e gerenciar as informações de maneira mais estruturada.',
    categories: ['Categoria 1'],
  });

  // Remova useEffect se não estiver buscando dados da API
  useEffect(() => {
    // Simulação de dados, substitua com chamadas reais à API quando necessário
    // Exemplo de dados estáticos já configurados acima
  }, []);

  const handleSelectChange = (value) => {
    if (value.includes('all')) {
      if (value.length === categories.length + 1) {
        setSelectedCategories(value.filter((item) => item !== 'all'));
      } else {
        setSelectedCategories(categories.map((category) => category.name));
      }
    } else {
      setSelectedCategories(value);
    }
  };

  const handleSave = () => {
    console.log('Categorias selecionadas:', selectedCategories);
  };

  const handleCancel = () => {
    setSelectedCategories([]);
  };

  return (
    <Row justify="center" align="middle" style={{ padding: '10px' }}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Breadcrumb style={{ marginBottom: 16 }}>
          <Breadcrumb.Item> <a href="/streams">Fluxos Conversacionais</a></Breadcrumb.Item>
          <Breadcrumb.Item>Detalhes do Fluxo</Breadcrumb.Item>
        </Breadcrumb>

        <Card>
          <Title level={4}>Detalhes do Fluxo</Title>
          {stream ? (
            <>
              <Title level={5}>{stream.name}</Title>
              <Paragraph>{stream.description}</Paragraph>
              <Timeline mode="alternate">
                <Timeline.Item color="green" position="right">
                  Adiciona a categoria na Ocorrência
                </Timeline.Item>

                <Timeline.Item
                  dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}
                  position="left"
                  color="red"
                >
                  Caso o agente não responda, solicita novamente o agente após 1 hora
                </Timeline.Item>

                <Timeline.Item
                  color="red"
                  dot={<MenuOutlined style={{ fontSize: '18px' }} />}
                  position="left"
                >
                  Exibe as opções de categorias para o agente:
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Selecione as categorias"
                    onChange={handleSelectChange}
                    value={selectedCategories}
                  >
                    <Option key="all" value="all">
                      Selecionar Todos
                    </Option>
                    {categories.map((category) => (
                      <Option key={category.id} value={category.name}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </Timeline.Item>

                <Timeline.Item color="red" position="right">
                  Solicita categorização
                </Timeline.Item>

                <Timeline.Item position="right">
                  Identificação de Ocorrências
                </Timeline.Item>

                <Timeline.Item
                  dot={<MessageOutlined style={{ fontSize: '18px' }} />}
                  position="right"
                >
                  Conjunto de mensagens recebidas
                </Timeline.Item>
              </Timeline>
              <div style={{ textAlign: 'center', marginTop: 20 }}>
                <Button type="primary" onClick={handleSave} style={{ marginRight: 10 }}>
                  Salvar
                </Button>
                <Button onClick={handleCancel}>Cancelar</Button>
              </div>
            </>
          ) : (
            <Paragraph>Carregando...</Paragraph>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default StreamDetail;
