const isProduction = process.env.NODE_ENV === 'production';

const API_BASE_URL = isProduction
    ? process.env.REACT_APP_API_BASE_URL_PROD
    : process.env.REACT_APP_API_BASE_URL_DEV;

export const API_ENDPOINTS = {
    agents: `${API_BASE_URL}/agents`,
    companies: `${API_BASE_URL}/companies`,
    contracts: `${API_BASE_URL}/contracts`,
    users: `${API_BASE_URL}/users`,
    auth: `${API_BASE_URL}/auth`,
    status: `${API_BASE_URL}/status`,
    places: `${API_BASE_URL}/places`,
    places_group: `${API_BASE_URL}/places_group`,
    criticalities: `${API_BASE_URL}/criticalities`,
    categories: `${API_BASE_URL}/categories`,
    occurrence_detail: `${API_BASE_URL}/occurrence_detail`,
    occurrence: `${API_BASE_URL}/occurrences`,
    current_date: `${API_BASE_URL}/current-date`,
    images: `${API_BASE_URL}/iv`,
    images_api: `${API_BASE_URL}/images_api`,
    group_references: `${API_BASE_URL}/group_references`,
    messages: `${API_BASE_URL}/messages`,
    messages_calendar: `${API_BASE_URL}/messages/calendar`,
    search: `${API_BASE_URL}/search`,
};
