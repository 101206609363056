import React, { useState, useEffect } from 'react';
import { Table, Space, Breadcrumb, Row, Col, Card, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import './MessageListPage.css';
import { API_ENDPOINTS } from '../../../config/apiConfig';



const MessageListPage = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.messages}/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 403) {
        localStorage.removeItem('token');
        navigate('/login'); 
        return;
      }

      if (response.ok) {
        const data = await response.json();
        setMessages(data);
      } else {
        console.error('Erro ao buscar mensagens:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao buscar mensagens:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      render: (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString(); // Formatar a data de forma legível
      },
    },
    {
      title: 'Remetente',
      dataIndex: 'sender',
      key: 'sender',
      
    },
    {
      title: 'Mensagem',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'File Path',
      dataIndex: 'file_path',
      key: 'file_path',
    },
    {
      title: 'File Type',
      dataIndex: 'file_type',
      key: 'file_type',
    },
    {
      title: 'ID MSG',
      dataIndex: 'telegram_message_id',
      key: 'telegram_message_id',
      sorter: true,

    },
    {
      title: 'ID do Usuário',
      dataIndex: 'user_id',
      key: 'user_id',
    },
    // Adicione outras colunas para os demais campos do payload, se necessário
  ];

  return (
    <div className='MessageListPage'>
      <Row justify="left" align="middle" style={{ padding: '10px' }}>
        <Breadcrumb style={{ marginBottom: 16 }}>
          {/* ... (Defina o breadcrumb de acordo com a sua aplicação) */}
          <Breadcrumb.Item>Mensagens</Breadcrumb.Item> 
        </Breadcrumb>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            {loading ? (
              <Spin tip="Carregando mensagens..." />
            ) : (
              <Table size='small'
              pagination={false}

              scroll={{
                y: 400,
                x: 1300,
              }}
              columns={columns} dataSource={messages} rowKey="id" />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MessageListPage;