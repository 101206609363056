import React, { useState, useEffect } from 'react';
import { Button, Table, Space, Breadcrumb, Row, Col, Card, Spin } from 'antd';
import { useNavigate } from 'react-router-dom'; 
import AddUserModal from './AddUserModal';
import EditUserModal from './EditUserModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { API_ENDPOINTS } from '../../../config/apiConfig';


const UserListPage = () => {
  const [users, setUsers] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.users}/`, {

        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 403) {
        localStorage.removeItem('token');
        navigate('/login'); 
        return;
      }

      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        console.error('Erro ao buscar usuários:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const handleUserUpdated = () => {
    fetchUsers();
    setShowEditModal(false);
    setShowDeleteModal(false);
  };

  const columns = [
    { title: 'Nome', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'status',
      render: (active) => (active ? 'Ativo' : 'Inativo'),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, user) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleEditClick(user)}>Editar</Button>
          <Button type="link" danger onClick={() => handleDeleteClick(user)}>Excluir</Button>
        </Space>
      ),
    },
  ];

  return (
    <div className='container'>
      <Row justify="left" align="middle" style={{ padding: '10px' }}>
        <Breadcrumb style={{ marginBottom: 16 }}>
          <Breadcrumb.Item>Gestão</Breadcrumb.Item>
          <Breadcrumb.Item>Administração</Breadcrumb.Item>
          <Breadcrumb.Item>Usuários</Breadcrumb.Item>
        </Breadcrumb>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <Button type="primary" onClick={() => setShowAddModal(true)}>Adicionar Usuário</Button>
            {loading ? (
              <Spin size="large" />
            ) : (
              <Table size='small' dataSource={users} columns={columns} rowKey="id" style={{ marginTop: 16 }} />
            )}
            <AddUserModal
              visible={showAddModal}
              onCancel={() => setShowAddModal(false)}
              reloadUsers={fetchUsers}
            />
            <EditUserModal
              visible={showEditModal}
              onCancel={() => setShowEditModal(false)}
              user={selectedUser}
              handleUserUpdated={handleUserUpdated}
            />
            <DeleteConfirmationModal
              visible={showDeleteModal}
              onCancel={() => setShowDeleteModal(false)}
              user={selectedUser}
              handleUserUpdated={handleUserUpdated}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserListPage;
