import React, { useState } from 'react';
import { Image, Button } from 'antd';
import { API_ENDPOINTS } from '../../../config/apiConfig'; // Certifique-se de que o caminho está correto

import './OccurrenceImages.css';

const OccurrenceImages = ({ images }) => {
    const [visible, setVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null); // Para armazenar a imagem selecionada para visualização
    const imagePath = '/thumb/'; // Caminho intermediário, ajuste conforme necessário

    const handleImagePreview = (fullImagePath) => {
        setSelectedImage(fullImagePath);
        setVisible(true); // Abre a visualização
    };

    return (
        <div className='row'>
            <div className='col-lg-12'>
                <h3>Imagens</h3>
                <div className='grid-container'>
                    {images.map((item, index) => {
                        const thumbImagePath = `${API_ENDPOINTS.images}${imagePath}${item.thumb}`; // Caminho da miniatura
                        const fullImagePath = `${API_ENDPOINTS.images}/view/${item.thumb}`; // Caminho da imagem completa
                        return (
                            <div className="image-thumbnail" key={index}>
                                <img
                                    width={120}
                                    src={thumbImagePath}
                                    alt={`Thumbnail ${index + 1}`}
                                    style={{ cursor: 'pointer' }} // Para indicar que a imagem é clicável
                                    onClick={() => handleImagePreview(fullImagePath)} // Dispara a visualização ao clicar
                                />
                            </div>
                        );
                    })}
                </div>

                {/* Componente de visualização da imagem */}
                {selectedImage && (
                    <Image
                        style={{ display: 'none' }} // Escondido até ser ativado
                        src={selectedImage}
                        preview={{
                            visible: visible,
                            src: selectedImage,
                            onVisibleChange: (value) => setVisible(value), // Controla a visibilidade do preview
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default OccurrenceImages;
