// src/pages/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Alert, Typography } from 'antd';
import axios from 'axios';
import { API_ENDPOINTS } from '../config/apiConfig';
import './Login.css';



function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    try {
      const response = await axios.post(`${API_ENDPOINTS.auth}/login`, {
        email: values.email,
        password: values.password,
      });

      const { token } = response.data;

      localStorage.setItem('token', token);

      const userResponse = await axios.get(`${API_ENDPOINTS.auth}/protected`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      navigate('/dashboard', { state: { message: userResponse.data.message } });
    } catch (error) {
      setError('Email ou senha incorretos!');
    }
  };

  return (

    <div className='login-bg'>
        <div className='login'>
        <div className='login-card'>
          <div style={{ width: '100%', maxWidth: '300px' }}>
          <img src="/otalk-azul.png" alt="Logo" />

            {error && <Alert message={error} type="error" showIcon style={{ marginBottom: '24px' }} />}
            <Form
              layout="vertical"
              onFinish={handleLogin}
              initialValues={{ email: email, password: password }}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Por favor, insira seu email!' }]}
              >
                <Input
                  type="email"
                  placeholder="Digite seu email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label="Senha"
                name="password"
                rules={[{ required: true, message: 'Por favor, insira sua senha!' }]}
              >
                <Input.Password
                  placeholder="Digite sua senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
