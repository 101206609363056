import React from 'react';
import './OccurrenceAudios.css'; // Crie um arquivo CSS para estilizar se necessário

const OccurrenceAudios = ({ audios }) => {
    if (audios.length === 0) {
        return null; // Ou renderize algo específico para quando não há áudios
    }

    return (
        <div className='row'>
            <h3>Áudios</h3>
            {audios.map((audio, index) => (
                <div key={index} className='row'>
                    <div className='col-lg-4'>
                        <audio controls>
                            <source src={`/audio/${audio.original_audio}`} type="audio/mp3" />
                            Seu navegador não suporta o elemento de áudio.
                        </audio>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default OccurrenceAudios;
