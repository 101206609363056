import React, { useEffect } from 'react';
import { Modal, Form, Input, Checkbox, Button } from 'antd';
import { API_ENDPOINTS } from '../../../config/apiConfig';

const EditUserModal = ({ visible, onCancel, user, handleUserUpdated }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible && user) {
      form.setFieldsValue({
        name: user.name,
        email: user.email,
        active: user.active,
      });
    }
  }, [visible, user, form]);

  const handleEdit = async () => {
    try {
      const values = await form.validateFields();
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.users}/${user.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        handleUserUpdated();
        onCancel();
      } else {
        console.error('Erro ao editar usuário:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao editar usuário:', error);
    }
  };

  return (
    <Modal
      title={`Editar Usuário (${user?.name || ''})`}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleEdit}>
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Nome"
          rules={[{ required: true, message: 'Por favor, insira o nome!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: 'Por favor, insira o email!' }]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item name="active" valuePropName="checked">
          <Checkbox>Ativo</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditUserModal;
