import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Breadcrumb, Card, Image, Typography } from 'antd';
import { API_ENDPOINTS } from '../../config/apiConfig';
import './ImageDetail.css';


const { Text } = Typography;

const ImageDetail = () => {
    const { id } = useParams(); // Este id agora é considerado o ID da mensagem
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchMessageDetails();
    }, [id]);

    const fetchMessageDetails = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${API_ENDPOINTS.messages}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (response.ok) {
                const data = await response.json();
                setMessage(data);
            } else {
                console.error('Failed to fetch message details:', response.statusText);
                navigate('/images'); // Redirecionar se houver erro
            }
        } catch (error) {
            console.error('Error fetching message details:', error);
            navigate('/images');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='container'>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item onClick={() => navigate('/images')}>Imagens</Breadcrumb.Item>
                <Breadcrumb.Item>Detalhe da Mensagem</Breadcrumb.Item>
            </Breadcrumb>
            <Card loading={loading}>
                {message && (
                    <>
                        <Text strong>{message.sender}</Text>
                        <Text type="secondary">{new Date(message.date).toLocaleString()}</Text>
                        <Text italic>{message.message}</Text>
                        {message.file_path && (
                            <div className='ImageDetail'>
                                <div className='a'>x</div>
                                <div className='b'>x</div>
                                <div className='c'>x</div>
                                <div className='d'>x</div>
                                <div className='e'>x</div>
                                <div className='f'>x</div>

                                <Image
                                    src={`${API_ENDPOINTS.images}/${message.file_path}`} // Ajuste necessário se a API de mensagens usa um endpoint diferente para imagens
                                    alt={message.message}
                                    style={{ objectFit: 'contain' }}
                                    preview={false}
                                />
                            </div>
                        )}
                    </>
                )}
            </Card>
        </div>
    );
};

export default ImageDetail;
