import React, { useEffect } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { API_ENDPOINTS } from '../../../config/apiConfig';


const EditAgentModal = ({ visible, onCancel, agent, handleAgentUpdated }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible && agent) {
      form.setFieldsValue({
        name: agent.name,
        origin: agent.origin,
        origin_id: agent.origin_id,
      });
    }
  }, [visible, agent, form]);

  const handleEdit = async () => {
    try {
      const values = await form.validateFields();
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.agents}/${agent.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          company_id: 0, // Certifique-se de que esse valor é adequado
          ...values
        }),
      });

      if (response.ok) {
        handleAgentUpdated();
        onCancel();
      } else {
        console.error('Erro ao editar agente:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao editar agente:', error);
    }
  };

  return (
    <Modal
      title={`Editar Agente (${agent?.name || ''})`}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleEdit}>
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Nome"
          rules={[{ required: true, message: 'Por favor, insira o nome!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="origin"
          label="Origem"
          rules={[{ required: true, message: 'Por favor, insira a origem!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="origin_id"
          label="ID de Origem"
          rules={[{ required: true, message: 'Por favor, insira o ID de origem!' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditAgentModal;
