import React from 'react';

const OccurrenceVideos = ({ videos }) => {
    return (
        <div className='row'>
            <div className='col-lg-12'>
                <h3>Vídeos</h3>
                {videos.map((video, index) => (
                    <div key={index} className='video-container'>
                        <video controls className='video'>
                            <source src={`/videos/${video.src}`} type="video/mp4" />
                            Seu navegador não suporta o elemento de vídeo.
                        </video>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OccurrenceVideos;
