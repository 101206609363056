// ThumbnailCard.js
import React from 'react';
import { API_ENDPOINTS } from '../../config/apiConfig'; 
import './ThumbnailCard.css'; 

const ThumbnailCard = ({ images, totalImages }) => {
  totalImages = typeof totalImages === 'number' ? totalImages : images.length;

  const imagePath = '/thumb/'; 

  const renderImages = () => {
    if (images.length === 0) {
      return (
        <div className='images-no-image'>
          <div className='item'>
            <span>sem imagens</span>
          </div>
        </div>
      );
    }

    const renderImage = (imageFileName, index) => (
      <div key={index} className='item'>
        <img src={`${API_ENDPOINTS.images}/${imagePath}${imageFileName}`} alt={`Thumbnail ${index + 1}`} />
      </div>
    );

    return (
      <div className={`images-more-${images.length > 2 ? '4' : images.length}`}>
        {images.slice(0, images.length > 2 ? 2 : images.length).map(renderImage)}
        {images.length > 2 && (
          <div className='item'>
            <div className='see-more'>+ {totalImages - images.length}</div>
            {renderImage(images[images.length - 1], images.length - 1)}
          </div>
        )}
      </div>
    );
  };

  return <>{renderImages()}</>;
};

export default ThumbnailCard;
