import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Card, Row, Col, Spin, Breadcrumb } from 'antd';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import OccurrenceInfo from './OccurrenceInfo';
import OccurrenceImages from './OccurrenceImages';
import OccurrenceMessages from './OccurrenceMessages';
import OccurrenceVideos from './OccurrenceVideos';
import ReferencesManager from './ReferencesManager';
import OccurrenceAudios from './OccurrenceAudios';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../../../config/apiConfig';
import './OccurrenceDetail.css';

const OccurrenceDetail = () => {
    const { id } = useParams();
    const [open, setOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [occurrenceData, setOccurrenceData] = useState(null);
    const [categories, setCategories] = useState([]);
    const [places, setPlaces] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchOccurrenceData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${API_ENDPOINTS.occurrence_detail}/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.status === 403) {
                    localStorage.removeItem('token');
                    navigate('/login');
                    return;
                }

                setOccurrenceData(response.data);
            } catch (error) {
                console.error('Error fetching occurrence data:', error);
            }
        };

        const fetchAllCategories = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${API_ENDPOINTS.categories}/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        const fetchAllPlaces = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${API_ENDPOINTS.places}/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                setPlaces(response.data);
            } catch (error) {
                console.error('Error fetching places:', error);
            }
        };

        const fetchAllStatuses = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${API_ENDPOINTS.status}/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                setStatuses(response.data);
            } catch (error) {
                console.error('Error fetching statuses:', error);
            }
        };

        fetchOccurrenceData();
        fetchAllCategories();
        fetchAllPlaces();
        fetchAllStatuses();
    }, [id]);

    if (!occurrenceData || !categories.length || !places.length || !statuses.length) {
        return <Spin size="large" />;
    }

    const handleImageClick = (index) => {
        setCurrentIndex(index);
        setOpen(true);
    };

    const slides = occurrenceData.images?.map(image => ({
        src: `/images/${image.thumb}`, // Ajuste conforme necessário
    })) || [];

    return (
        <div className="container mt-4">

            <Breadcrumb>
                <Breadcrumb.Item>Ocorrências</Breadcrumb.Item>
                <Breadcrumb.Item>Ocorrência sem título</Breadcrumb.Item>
            </Breadcrumb>


            <OccurrenceInfo className="mb-2" occurrenceData={occurrenceData} />
            <Row gutter={16} className="mt-4">
                <Col span={24}>
                    <Card>
                        <ReferencesManager
                            id={id}
                            categories={categories}
                            places={places}
                            statuses={statuses}
                            onReferencesChange={() => {
                                // Aqui, você pode adicionar lógica adicional se necessário
                            }}
                        />
                    </Card>
                </Col>
            </Row>

            <Card>
                <Row gutter={16}>
                    <Col span={24}>
                        <OccurrenceImages images={occurrenceData.images} onImageClick={handleImageClick} />
                        <OccurrenceVideos videos={occurrenceData.videos} />
                        <OccurrenceAudios audios={occurrenceData.audios} />
                    </Col>
                </Row>
                <Row gutter={16} className="mt-4">
                    <Col span={24}>
                        <OccurrenceMessages messages={occurrenceData.messages} />
                    </Col>
                </Row>

                <Lightbox
                    open={open}
                    close={() => setOpen(false)}
                    slides={slides}
                    currentIndex={currentIndex}
                />
            </Card>
        </div>
    );
};

export default OccurrenceDetail;