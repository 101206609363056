import React from 'react';
import { Modal, Button } from 'antd';
import { API_ENDPOINTS } from '../../../config/apiConfig';


const DeleteConfirmationModal = ({ visible, onClose, contract, onContractDeleted }) => {
  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.contracts}/${contract.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        onContractDeleted();
        onClose();
      } else {
        console.error('Erro ao excluir contrato:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao excluir contrato:', error);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Confirmar Exclusão"
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>Cancelar</Button>,
        <Button key="confirm" type="primary" danger onClick={handleDelete}>
          Excluir
        </Button>,
      ]}
    >
      <p>Tem certeza que deseja excluir o contrato {contract?.title}?</p>
    </Modal>
  );
};

export default DeleteConfirmationModal;
