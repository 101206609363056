import React, { useRef, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import TopLoadingBar from 'react-top-loading-bar';
import ThemeConfig from './theme/themeConfig';
import AppRoutes from './routes/AppRoutes';
import './App.css';

function App() {
  const loadingBarRef = useRef(null);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => setIsDarkMode(!isDarkMode);
  const simulateLoading = () => {
    loadingBarRef.current?.continuousStart();
    setTimeout(() => loadingBarRef.current?.complete(), 2000);
  };

  React.useEffect(() => simulateLoading(), []);

  return (
    <ThemeConfig isDarkMode={isDarkMode}>
      <Router>
        <TopLoadingBar color="#f11946" height={3} ref={loadingBarRef} />
        <div style={{ backgroundColor: isDarkMode ? '#212121' : '#f0f0f2', minHeight: '100vh' }}>
          <AppRoutes isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
        </div>
      </Router>
    </ThemeConfig>
  );
}

export default App;
