import React, { useState, useEffect } from 'react';
import { Button, Table, Space, Breadcrumb, Row, Col, Card, Spin } from 'antd';
import { useNavigate } from 'react-router-dom'; // Atualizar a importação
import AddContractModal from './AddContractModal';
import EditContractModal from './EditContractModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { API_ENDPOINTS } from '../../../config/apiConfig';


const ContractListPage = () => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchContracts();
  }, []);

  const fetchContracts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.contracts}/`, {

        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 403) {
        localStorage.removeItem('token');
        navigate('/login'); 
        return;
      }

      if (response.ok) {
        const data = await response.json();
        setContracts(data);
      } else {
        console.error('Erro ao buscar contratos:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao buscar contratos:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (contract) => {
    setSelectedContract(contract);
    setShowEditModal(true);
  };

  const handleDeleteClick = (contract) => {
    setSelectedContract(contract);
    setShowDeleteModal(true);
  };

  const handleContractUpdated = () => {
    fetchContracts();
    setShowEditModal(false);
    setShowDeleteModal(false);
  };

  const columns = [
    {
      title: 'Nome do Contrato',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Número do Contrato',
      dataIndex: 'contract_number',
      key: 'contract_number',
    },
    {
      title: 'Responsável Técnico',
      dataIndex: 'technical_responsible',
      key: 'technical_responsible',
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      render: (active) => (active ? 'Sim' : 'Não'),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleEditClick(record)}>Editar</Button>
          <Button type="link" danger onClick={() => handleDeleteClick(record)}>Excluir</Button>
        </Space>
      ),
    },
  ];

  return (
    <div className='container'>
      <Row justify="left" align="middle" style={{ padding: '10px' }}>
        <Breadcrumb style={{ marginBottom: 16 }}>
          <Breadcrumb.Item>Gestão</Breadcrumb.Item>
          <Breadcrumb.Item>Operação</Breadcrumb.Item>
          <Breadcrumb.Item>Contratos</Breadcrumb.Item>
        </Breadcrumb>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <Button type="primary" onClick={() => setShowAddModal(true)}>Adicionar Contrato</Button>
            {loading ? (
              <Spin tip="Carregando contratos..." />
            ) : (
              <Table size='small' columns={columns} dataSource={contracts} rowKey="id" />
            )}
          </Card>
          <AddContractModal
            visible={showAddModal}
            onClose={() => setShowAddModal(false)}
            onContractAdded={handleContractUpdated}
          />
          <EditContractModal
            visible={showEditModal}
            onClose={() => setShowEditModal(false)}
            contract={selectedContract}
            onContractUpdated={handleContractUpdated}
          />
          <DeleteConfirmationModal
            visible={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            contract={selectedContract}
            onContractDeleted={handleContractUpdated}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ContractListPage;
