import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div></div>
    /*
    <div className="d-flex flex-column p-3 bg-light" style={{ width: '250px' }}>
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <Link to="/operation/category" className="nav-link">
            Categorias
          </Link>
        </li>
        <li>
          <Link to="/operation/status" className="nav-link">
            Stauts
          </Link>
        </li>
        <li>
          <Link to="/operation/places" className="nav-link">
            Localidades
          </Link>
        </li>
        <li>
          <Link to="/operation/criticalities" className="nav-link">
            Criticidades
          </Link>
        </li>
      </ul>
    </div>
    */
  );
};

export default Sidebar;
