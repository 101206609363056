import React from 'react';
import { Drawer, Form, Input, Button, Checkbox } from 'antd';
import { API_ENDPOINTS } from '../../../config/apiConfig'; 

const AddCompanyDrawer = ({ visible, onClose, onCompanyAdded }) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();

      formData.append('name', values.name);
      formData.append('company_number', values.company_number);
      formData.append('company_type', values.company_type);
      formData.append('description', values.description);
      formData.append('active', values.active ? 1 : 0);

      if (values.image && values.image.file) {
        formData.append('file', values.image.file);
      }

      const response = await fetch(`${API_ENDPOINTS.companies}/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        onCompanyAdded();
        onClose();
        form.resetFields();
      } else {
        console.error('Erro ao adicionar empresa:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao adicionar empresa:', error);
    }
  };

  return (
    <Drawer
      title="Adicionar Empresa"
      width={720}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancelar
          </Button>
          <Button onClick={() => form.submit()} type="primary">
            Adicionar
          </Button>
        </div>
      }
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Por favor, insira o nome!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="company_number" label="CNPJ" rules={[{ required: true, message: 'Por favor, insira o CNPJ!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="company_type" label="Tipo de Empresa" rules={[{ required: true, message: 'Por favor, insira o tipo de empresa!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Descrição" rules={[{ required: true, message: 'Por favor, insira uma descrição!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="active" valuePropName="checked">
          <Checkbox>Ativa</Checkbox>
        </Form.Item>
        <Form.Item name="image" label="Imagem">
          <Input type="file" />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddCompanyDrawer;
