import React, { useState, useEffect } from 'react';
import { Button, Table, Space, Breadcrumb, Row, Col, Card, Spin, Image } from 'antd';
import {useNavigate } from 'react-router-dom'; 
import AddCompanyDrawer from './AddCompanyDrawer'; 
import EditCompanyDrawer from './EditCompanyDrawer'; 
import DeleteConfirmationModal from './DeleteConfirmationModal';
import AgentDrawer from './AgentDrawer';
import { API_ENDPOINTS } from '../../../config/apiConfig';


const CompanyListPage = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAgentDrawer, setShowAgentDrawer] = useState(false);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.companies}/`, {

        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 403) {
        localStorage.removeItem('token')
        const data = await response.json();
        if (data.message === "Token expirado!") {
          navigate('/login');
        }
      } else if (response.ok) {
        const data = await response.json();
        setCompanies(data);
      } else {
        console.error('Erro ao buscar empresas:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao buscar empresas:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (company) => {
    setSelectedCompany(company);
    setShowEditDrawer(true);
  };

  const handleDeleteClick = (company) => {
    setSelectedCompany(company);
    setShowDeleteModal(true);
  };

  const handleCompanyUpdated = () => {
    fetchCompanies();
    setShowEditDrawer(false);
    setShowDeleteModal(false);
  };

  const handleAgentCountClick = (record) => {
    setSelectedCompany(record);
    setShowAgentDrawer(true);
  };

  const handleAgentDrawerClose = () => {
    setShowAgentDrawer(false);
    fetchCompanies(); // Atualiza a lista de empresas após o fechamento do AgentDrawer
  };

  const formatCNPJ = (cnpj) => {
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
  };

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Imagem',
      dataIndex: 'image',
      key: 'image',
      render: (text) => (
        text ? (
          <Image
            width={100}
            src={`${API_ENDPOINTS.images}/${text}`}
            alt="Imagem da empresa"
          />
        ) : null
      ),
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'CNPJ',
      dataIndex: 'company_number',
      key: 'company_number',
      render: (company_number) => formatCNPJ(company_number),
    },
    {
      title: 'Tipo de Empresa',
      dataIndex: 'company_type',
      key: 'company_type',
      render: (company_type) => {
        switch (company_type) {
          case 1:
            return <strong>Contratante</strong>;
          case 2:
            return 'Contratada';
          default:
            return 'Tipo desconhecido';
        }
      },
    },
    {
      title: 'Ativa',
      dataIndex: 'active',
      key: 'active',
      render: (active) => (active ? 'Sim' : 'Não'),
    },
    {
      title: 'Agentes',
      dataIndex: 'agent_count',
      key: 'agent_count',
      render: (agent_count, record) => {
        const formattedCount = agent_count === 0 ? 'sem agentes' :
          agent_count === 1 ? '1 agente' :
            `${agent_count} agentes`;

        return (
          <a onClick={() => handleAgentCountClick(record)}>
            {formattedCount}
          </a>
        );
      },
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleEditClick(record)}>Editar</Button>
          <Button type="link" danger onClick={() => handleDeleteClick(record)}>Excluir</Button>
        </Space>
      ),
    },
  ];

  return (
    <div className='container'>
      <Row justify="left" align="middle" style={{ padding: '10px' }}>
        <Breadcrumb style={{ marginBottom: 16 }}>
          <Breadcrumb.Item>Gestão</Breadcrumb.Item>
          <Breadcrumb.Item>Administração</Breadcrumb.Item>
          <Breadcrumb.Item>Empresas</Breadcrumb.Item>
        </Breadcrumb>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <Button type="primary" onClick={() => setShowAddDrawer(true)}>Adicionar Empresa</Button>
            {loading ? (
              <Spin tip="Carregando empresas..." />
            ) : (
              <Table size='small' columns={columns} dataSource={companies} rowKey="id" />
            )}
          </Card>
          <AddCompanyDrawer
            visible={showAddDrawer}
            onClose={() => setShowAddDrawer(false)}
            onCompanyAdded={handleCompanyUpdated}
          />
          <EditCompanyDrawer
            visible={showEditDrawer}
            onClose={() => setShowEditDrawer(false)}
            company={selectedCompany}
            onCompanyUpdated={handleCompanyUpdated}
          />
          <DeleteConfirmationModal
            visible={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            company={selectedCompany}
            onCompanyDeleted={handleCompanyUpdated}
          />
          {selectedCompany && (
            <AgentDrawer
              visible={showAgentDrawer}
              onClose={handleAgentDrawerClose}
              company={selectedCompany}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CompanyListPage;