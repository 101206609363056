import React from 'react';
import { Modal, Button } from 'antd';
import { API_ENDPOINTS } from '../../../config/apiConfig';


const DeleteConfirmationModal = ({ visible, onCancel, user, handleUserUpdated }) => {
  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.users}/${user.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        handleUserUpdated();
        onCancel();
      } else {
        console.error('Erro ao excluir usuário:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao excluir usuário:', error);
    }
  };

  return (
    <Modal
      title="Excluir Usuário"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="delete" type="danger" onClick={handleDelete}>
          Excluir
        </Button>,
      ]}
    >
      <p>Tem certeza de que deseja excluir o usuário <strong>{user?.name}</strong>?</p>
    </Modal>
  );
};

export default DeleteConfirmationModal;
