// src/components/MapComponent.js

import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip, useMap } from 'react-leaflet'; 
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet.heat'; // Importa o plugin de Heatmap

// Importa o ícone personalizado
import customMarker from '../location.svg'; // Ajuste o caminho conforme necessário

// Define o ícone personalizado
const customIcon = new L.Icon({
  iconUrl: customMarker,
  iconRetinaUrl: customMarker, // Opcional: se você tiver uma versão retina do ícone
  iconSize: [25, 25], // Tamanho do ícone [largura, altura]
  iconAnchor: [12, 25], // Ponto do ícone que corresponde à posição do marcador
  popupAnchor: [1, -34], // Ponto onde o popup será aberto relativo ao ícone
  tooltipAnchor: [16, -28], // Ponto onde o tooltip será aberto relativo ao ícone
  shadowUrl: null, // Remova a sombra padrão ou adicione uma personalizada
  shadowSize: null,
  shadowAnchor: null,
});

// Componente de camada de Heatmap
const HeatmapLayer = ({ heatmapData }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    // Converte os dados para o formato exigido pelo heatmap (lat, lng, intensidade)
    const points = heatmapData.map(item => [
      item.position[0], // latitude
      item.position[1], // longitude
      item.quanty_groups || 1 // intensidade baseada no quanty_groups
    ]);

    // Adiciona a camada de heatmap ao mapa
    const heatLayer = L.heatLayer(points, { 
      radius: 25,  // Raio do "calor" base
      blur: 30,    // Quantidade de "borrão"
      maxZoom: 17, // Zoom máximo para renderizar o heatmap
    }).addTo(map);

    return () => {
      // Remove a camada de heatmap ao desmontar o componente
      map.removeLayer(heatLayer);
    };
  }, [map, heatmapData]);

  return null;
};

// Componente principal do Mapa
const MapComponent = ({ markersData, showDrawer }) => {
  const [displayData, setDisplayData] = useState([]);

  // Verificar se os dados estão corretos
  console.log("Markers Data:", markersData);

  // Mapeando dados para o heatmap e para a exibição
  const heatmapData = markersData.map(marker => {
    const position = Array.isArray(marker.position) 
      ? marker.position 
      : marker.position.split(', ').map(Number); // Converte a string para array de números

    const quantyGroups = marker.quanty_groups || 'N/A'; // Exibe 'N/A' se não houver valor de quanty_groups

    return {
      position: position,
      quanty_groups: quantyGroups, // Atribuindo o valor correto de quanty_groups
      name: marker.title || marker.name // Assegura que o nome está definido
    };
  });

  useEffect(() => {
    setDisplayData(heatmapData); // Atualiza os dados a serem exibidos na lista
  }, [heatmapData]);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%' }}>
        <MapContainer
          center={[-20.32544461860242, -40.3379473523349653]} // Centraliza o mapa
          zoom={15} // Define o nível de zoom inicial
          style={{ height: '500px', width: '100%' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
            url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
          />

          {/* Componente de Heatmap */}
          <HeatmapLayer heatmapData={heatmapData} />

          {/* Marcadores personalizados */}
          {markersData.map(marker => {
            const position = Array.isArray(marker.position) 
              ? marker.position 
              : marker.position.split(', ').map(Number); // Converte string para array de números

            return (
              <Marker
                key={marker.id} // Usar o ID como chave para garantir unicidade
                position={position}
                icon={customIcon} // Aplica o ícone personalizado
                eventHandlers={{
                  click: () => {
                    showDrawer(marker); // Chama o Drawer com os detalhes
                  },
                }}
              >
                <Tooltip>
                  <b>{marker.title}</b> {/* Exibe o nome no Tooltip */}
                </Tooltip>
              </Marker>
            );
          })}
        </MapContainer>
      </div>
    </div>
  );
};

export default MapComponent;
