import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Checkbox, Button } from 'antd';
import { API_ENDPOINTS } from '../../config/apiConfig';


const EditReferenceModal = ({ visible, onCancel, reference, referenceType, handleReferenceUpdated }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // Define os endpoints de API com base no tipo de referência
  const endpoints = {
    status: `${API_ENDPOINTS.status}/`, 
    places: `${API_ENDPOINTS.places}/`,
    criticalities: `${API_ENDPOINTS.criticalities}/`,
    categories: `${API_ENDPOINTS.categories}/`,
  };

  // Preenche o formulário quando a referência é recebida ou o modal é aberto
  useEffect(() => {
    if (reference) {
      form.setFieldsValue({
        name: reference.name,
        description: reference.description,
        active: reference.active,
      }); 
    }
  }, [reference, form]);

  // Lida com a edição da referência
  const handleEdit = async () => {
    try {
      const token = localStorage.getItem('token');
      const values = await form.validateFields();
      setLoading(true);

      const response = await fetch(`${endpoints[referenceType]}${reference.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: values.name,
          description: values.description,
          active: values.active,
          company_id: 1, // Ajuste conforme necessário
        }),
      });

      if (response.ok) {
        handleReferenceUpdated();
        onCancel();
      } else {
        console.error('Erro ao editar referência:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao editar referência:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title={`Editar ${referenceType.slice(0, -1)} (${reference?.name})`}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleEdit}>
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Nome"
          rules={[{ required: true, message: 'Por favor, insira o nome!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Descrição">
          <Input />
        </Form.Item>
        <Form.Item name="active" valuePropName="checked">
          <Checkbox>Ativo</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditReferenceModal;
