import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Controller } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './FilterByMonth.css';
import { API_ENDPOINTS } from '../../config/apiConfig'; 



const FilterByMonth = ({ currentMonth, onMonthClick, onDayClick }) => {
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [weeks, setWeeks] = useState([]);
    const [currentYear, setCurrentYear] = useState(null);
    const [currentDay, setCurrentDay] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null); // Adiciona estado para o dia selecionado
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const weekDays = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];

    useEffect(() => {
        const fetchCurrentDate = async () => {
            try {
                const response = await axios.get(`${API_ENDPOINTS.current_date}`);
                const currentDate = new Date(response.data.current_date);
                setCurrentYear(currentDate.getFullYear());
                setCurrentDay(currentDate.getDate());
                updateWeeksInMonth(selectedMonth, currentDate.getFullYear());
            } catch (error) {
                console.error('Error fetching current date:', error);
            }
        };

        fetchCurrentDate();
    }, [selectedMonth]);

    useEffect(() => {
        if (currentYear !== null) {
            updateWeeksInMonth(selectedMonth, currentYear);
        }
    }, [currentMonth, currentYear]);

    const handleMonthClick = (index) => {
        if (index <= currentMonth) {
            setSelectedMonth(index);
            setSelectedDay(null); // Deseleciona o dia ao mudar o mês
            onMonthClick(index);
        }
    };

    const handleDayClick = (day, isFutureDay) => {
        if (!isFutureDay) {
            if (day === selectedDay) {
                // Deseleciona o dia se ele já estiver selecionado
                setSelectedDay(null);
                onDayClick(null, null); // Passa valores nulos para indicar a deseleção
            } else {
                // Seleciona um novo dia
                setSelectedDay(day);
                const selectedDate = new Date(currentYear, selectedMonth, day);
                const start_date = `${currentYear}-${String(selectedMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
                const end_date = `${currentYear}-${String(selectedMonth + 1).padStart(2, '0')}-${String(day + 1).padStart(2, '0')}`;
                onDayClick(start_date, end_date);
            }
        }
    };

    const getDayOfWeek = (year, month, day) => {
        const date = new Date(year, month, day);
        return weekDays[date.getDay()];
    };

    const updateWeeksInMonth = (month, year) => {
        if (year !== undefined && year !== null) {
            const days = new Date(year, month + 1, 0).getDate();
            let firstDay = new Date(year, month, 1).getDay();

            let currentDay = 1;
            const weeksArray = [];

            while (currentDay <= days) {
                const week = [];
                for (let i = 0; i < 7; i++) {
                    if (currentDay > days) break;
                    if (i >= firstDay || weeksArray.length > 0) {
                        const isFutureDay = currentYear === year && selectedMonth === currentMonth && currentDay > new Date().getDate();
                        week.push({ day: currentDay, isFutureDay });
                        currentDay++;
                    } else {
                        week.push({ day: null });
                    }
                }
                weeksArray.push(week);
                firstDay = 0;
            }

            setWeeks(weeksArray);
        }
    };

    const swiperRef = useRef(null);

    const handlePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const findInitialSlide = () => {
        let slideIndex = 0;
        weeks.forEach((week, index) => {
            if (week.some(({ day }) => day === currentDay)) {
                slideIndex = index;
            }
        });
        return slideIndex;
    };

    return (
        <div>
            <div className="month-buttons">
                {months.map((month, index) => (
                    <Button
                        key={index}
                        type={index === selectedMonth ? 'primary' : 'default'}  // Define o tipo do botão com base na seleção
                        disabled={index > currentMonth}  // Desativa o botão se for um mês futuro
                        onClick={() => handleMonthClick(index)}
                        style={{ margin: '0 4px' }} // Adiciona margem para espaçamento
                    >
                        {month}
                    </Button>
                ))}
            </div>

            <div className="days-list">
                <div className='col-auto'>
                    <Button
                        icon={<LeftOutlined />}
                        onClick={handlePrev}
                        size="large"
                        type="default"
                    />
                </div>

                <div className='col'>
                    <Swiper
                        ref={swiperRef}
                        slidesPerView={'auto'}
                        centeredSlides={false}
                        spaceBetween={10}
                        grabCursor={true}
                        pagination={{ clickable: true }}
                        initialSlide={findInitialSlide()}
                        modules={[Navigation, Pagination, Controller]}
                        className="SwiperDays"
                    >
                        {weeks.map((week, index) => (
                            <SwiperSlide key={`week-${index}`}>
                                <div className='week'>
                                    {week.map(({ day, isFutureDay }, dayIndex) => (
                                        day === null ? (
                                            <div key={`empty-${dayIndex}`} className="day-item empty"></div>
                                        ) : (
                                            <Button
                                                key={`day-${day}`}
                                                className={`day-item ${isFutureDay ? 'opacity-25' : ''} ${day === selectedDay ? 'ant-btn-primary' : ''}`} // Aplica a classe primary se o dia estiver selecionado
                                                shape="circle"
                                                size="large"
                                                style={{ margin: '4px', cursor: isFutureDay ? 'not-allowed' : 'pointer', textAlign: 'center' }}
                                                onClick={() => handleDayClick(day, isFutureDay)}
                                            >
                                                <div className='day-number'>{String(day).padStart(2, '0')}</div>
                                                <div className='day-week'>{getDayOfWeek(currentYear, selectedMonth, day)}</div>
                                            </Button>
                                        )
                                    ))}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className='col-auto'>
                    <Button
                        icon={<RightOutlined />}
                        onClick={handleNext}
                        size="large"
                        type="default"
                    />
                </div>
            </div>
        </div>
    );
};

export default FilterByMonth;
