import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'antd';
import { ResponsiveCalendar } from '@nivo/calendar';
import { API_ENDPOINTS } from '../config/apiConfig';



const CalendarComponent = () => {
  const [calendarData, setCalendarData] = useState([]);
  const navigate = useNavigate(); // Hook para navegar entre rotas

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token'); // Obter o token do localStorage
        const response = await fetch(`${API_ENDPOINTS.messages_calendar}`, {


          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // Passar o token no cabeçalho Authorization
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 403) {
          localStorage.removeItem('token'); // Remover o token expirado
          const data = await response.json();
          if (data.message === "Token expirado!") {
            navigate('/login'); // Redirecionar para o login
          }
        } else if (response.ok) {
          const data = await response.json();
          if (Array.isArray(data)) {
            setCalendarData(data);
          } else {
            console.error('Os dados recebidos não são um array:', data);
          }
        } else {
          throw new Error('Falha na resposta da API');
        }
      } catch (error) {
        console.error('Erro ao buscar os dados do calendário:', error);
      }
    };

    fetchData();
  }, [navigate]); // Incluir navigate como dependência do useEffect

  return (
    <div>
      {calendarData.length > 0 ? (
        <div style={{ height: '120px' }}>
          <ResponsiveCalendar
            data={calendarData}
            from="2024-07-01"
            to="2024-09-30"
            emptyColor="#eeeeee"
            colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
            yearSpacing={40}
            monthBorderColor="#ffffff"
            dayBorderWidth={2}
            dayBorderColor="#ffffff"
            monthLegend={(year, month) => {
              const meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
              return meses[month];
            }}
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'row',
                translateY: 36,
                itemCount: 4,
                itemWidth: 42,
                itemHeight: 36,
                itemsSpacing: 14,
                itemDirection: 'right-to-left',
              },
            ]}
          />
        </div>
      ) : (
        <p>Carregando dados ou dados não disponíveis.</p>
      )}

    </div>
  );
};

export default CalendarComponent;
