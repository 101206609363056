import { ConfigProvider, theme } from 'antd';

const ThemeConfig = ({ children, isDarkMode }) => (
  <ConfigProvider
    theme={{
      algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
      

      token: {
        colorPrimary: '#0700c9',  
        colorLink: '#0700c9'
        
      },
    }}
  >
    {children}
  </ConfigProvider>
);

export default ThemeConfig;


