import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

const Layout = ({ children, isDarkMode, toggleTheme }) => {
  return (
    <div className="layout">
      <Header isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
      <Sidebar />
      <div className="main-content">
        {children}
      </div>
    </div>
  );
};

export default Layout;
