import React, { useState, useEffect } from 'react';
import { Drawer, Table, Avatar, Spin, Button, Tooltip, Modal } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import AddAgentsDrawer from './AddAgentsDrawer';
import { API_ENDPOINTS } from '../../../config/apiConfig';



const AgentDrawer = ({ visible, onClose, company }) => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [addAgentsVisible, setAddAgentsVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  useEffect(() => {
    if (visible && company) {
      fetchAgents();
    }
  }, [visible, company]);

  const fetchAgents = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.agents}/?company_id=${company.id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 403) {
        localStorage.removeItem('token')
        const data = await response.json();
        if (data.message === "Token expirado!") {
          navigate('/login');
        }
      } else if (response.ok) {
        const data = await response.json();
        setAgents(data);
      } else {
        console.error('Erro ao buscar agentes:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao buscar agentes:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddAgent = (agent) => {
    setAgents(prevAgents => [...prevAgents, agent]);
    setAddAgentsVisible(false);
    fetchAgents();
  };

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.agents}/batch-update`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedRowKeys.map(id => ({
          id,
          company_id: 0, // Define company_id como 0 conforme especificado
        }))),
      });

      if (response.status === 403) {
        localStorage.removeItem('token')
        const data = await response.json();
        if (data.message === "Token expirado!") {
          navigate('/login');
        }
      } else if (response.ok) {
        console.log('Agentes excluídos com sucesso');
        fetchAgents(); // Atualiza a lista de agentes após a exclusão
        setSelectedRowKeys([]);
        setDeleteModalVisible(false); // Fecha o modal após a exclusão
        onClose(); // Fecha o Drawer após a exclusão
      } else {
        console.error('Erro ao excluir agentes:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao excluir agentes:', error);
    }
  };

  const showDeleteConfirmation = () => {
    Modal.confirm({
      title: 'Você tem certeza que deseja excluir os agentes selecionados?',
      content: `Você está prestes a excluir ${selectedRowKeys.length} agente(s).`,
      okText: 'Excluir',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: handleDeleteConfirmation,
      onCancel() {
        console.log('Cancelado');
        setDeleteModalVisible(false);
      },
    });
  };

  const columns = [
    {
      title: 'Agente',
      dataIndex: 'name',
      key: 'name',
      render: (_, agent) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar style={{ backgroundColor: '#87d068', marginRight: 8 }}>
            {agent.name[0]}
          </Avatar>
          {agent.name}
        </div>
      ),
    },
  ];

  return (
    <>
      <Drawer
        title={`Agentes: ${company.name} (Código: ${company.id || 'Não disponível'})`}
        placement="right"
        onClose={onClose}
        visible={visible}
        width={500}
      >
        <div style={{ textAlign: 'right', marginBottom: 16 }}>
          <Tooltip placement="left" title={selectedRowKeys.length > 0 ? "Excluir agente(s)" : "Adicionar agente"}>
            <Button
              type="primary"
              shape="circle"
              icon={selectedRowKeys.length > 0 ? <DeleteOutlined /> : <PlusOutlined />}
              onClick={() => {
                if (selectedRowKeys.length > 0) {
                  showDeleteConfirmation(); // Mostra a confirmação de exclusão
                } else {
                  setAddAgentsVisible(true);
                }
              }}
              danger={selectedRowKeys.length > 0}
            />
          </Tooltip>
        </div>
        {loading ? (
          <Spin tip="Carregando agentes..." />
        ) : (
          <Table
            size='small'
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            columns={columns}
            dataSource={agents}
            rowKey="id"
          />
        )}
      </Drawer>
      <AddAgentsDrawer
        visible={addAgentsVisible}
        onClose={() => setAddAgentsVisible(false)}
        onAdd={handleAddAgent}
        company={company}
      />
      {deleteModalVisible && (
        <Modal
          title="Confirmar Exclusão"
          visible={deleteModalVisible}
          onOk={handleDeleteConfirmation}
          onCancel={() => setDeleteModalVisible(false)}
          okText="Excluir"
          cancelText="Cancelar"
          okType="danger"
        >
          <p>Você tem certeza que deseja excluir os agentes selecionados?</p>
          <p>Você está prestes a excluir {selectedRowKeys.length} agente(s).</p>
        </Modal>
      )}
    </>
  );
};

export default AgentDrawer;
