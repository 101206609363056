import React, { useState, useEffect } from 'react';
import { API_ENDPOINTS } from '../../config/apiConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Image, Divider } from 'antd';



const SearchResults = () => {
  const [searchResults, setSearchResults] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // useNavigate substitui useHistory

  // Função para buscar resultados de pesquisa
  const fetchSearchResults = async (query) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ENDPOINTS.search}/?query=${query}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 403) {
        localStorage.removeItem('token');
        return;
      }

      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Erro ao buscar resultados de pesquisa:', error);
    } finally {
      setLoading(false);
    }
  };

  // Captura o parâmetro de consulta da URL e dispara a busca
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('q');

    if (query) {
      fetchSearchResults(query);
    }
  }, [location.search]);

  // Função para formatar a data no formato brasileiro
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('pt-BR', options);
  };

  // Função para navegar para a página de detalhes
  const handleImageClick = (groupId) => {
    navigate(`/occurrences/detail/${groupId}`);
  };

  return (
    <div className='container'>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Card>
          {loading ? (
            <p>Buscando...</p>
          ) : (
            Object.entries(searchResults).map(([date, images]) => (
              <div key={date}>
                <h2>{formatDate(date)}</h2>
                <Row gutter={[16, 16]}>
                  {images.map(image => (
                    <Col key={image.id} xs={24} sm={12} md={8} lg={6} xl={4}>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleImageClick(image.group_id)}
                      >
                        <Image
                          src={`${API_ENDPOINTS.images}/view/${image.file_path}`}
                          alt={image.file_path}
                          width={150}
                          height={150}
                          style={{ objectFit: 'cover' }}
                          preview={{
                            src: `${API_ENDPOINTS.images}/${image.file_path}`,
                          }}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
                <Divider />
              </div>
            ))
          )}
        </Card>
      </Col>
    </div>
  );
};

export default SearchResults;
