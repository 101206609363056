import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Radio, Space } from 'antd';
import { LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';
import CalendarComponent from './CalendarComponent';
import StreamChart from './StreamChart';
import MapComponent from './MapComponent';
import 'leaflet/dist/leaflet.css';
import './Dashboard.css';
import { API_ENDPOINTS } from '../config/apiConfig';
import OccurrencesDrawer from './OccurrencesDrawer'; 

const { Title } = Typography;

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

const data = [
  {
    "MAX ENGENHARIA": 0,
    "ROTA ENGENHARIA": 0,
    "ENGEVIX": 1,
    "ENGNES": 2,
    "ROTACIONAL ENGENHARIA LTDA": 6
  },
  {
    "MAX ENGENHARIA": 0,
    "ROTA ENGENHARIA": 0,
    "ENGEVIX": 0,
    "ENGNES": 0,
    "ROTACIONAL ENGENHARIA LTDA": 8
  },
  {
    "MAX ENGENHARIA": 0,
    "ROTA ENGENHARIA": 0,
    "ENGEVIX": 0,
    "ENGNES": 0,
    "ROTACIONAL ENGENHARIA LTDA": 5
  },
  {
    "MAX ENGENHARIA": 0,
    "ROTA ENGENHARIA": 0,
    "ENGEVIX": 0,
    "ENGNES": 0,
    "ROTACIONAL ENGENHARIA LTDA": 8
  },
  {
    "MAX ENGENHARIA": 0,
    "ROTA ENGENHARIA": 0,
    "ENGEVIX": 0,
    "ENGNES": 0,
    "ROTACIONAL ENGENHARIA LTDA": 5
  },
];

// Componente principal
const Dashboard = () => {
  const [visible, setVisible] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState('7');
  const [markersData, setMarkersData] = useState([]);
  const [currentDate, setCurrentDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [occurrences, setOccurrences] = useState([]);
  const [loadingOccurrences, setLoadingOccurrences] = useState(false);

  // Função para buscar a data atual da API
  const fetchCurrentDate = async () => {
    try {
      const token = localStorage.getItem('token');

      const response = await fetch(`${API_ENDPOINTS.current_date}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        const fetchedDate = new Date(data.current_date);
        fetchedDate.setDate(fetchedDate.getDate() + 1);
        setCurrentDate(fetchedDate);
        console.log('Data atual recebida e ajustada:', fetchedDate);
      } else {
        console.error('Erro ao buscar data atual:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao buscar data atual:', error);
    }
  };

  // Função para buscar os dados dos marcadores
  const fetchMarkersData = async (daysAgo) => {
    if (!currentDate) return;

    const token = localStorage.getItem('token');
    const startDateObj = new Date(currentDate);
    startDateObj.setDate(startDateObj.getDate() - daysAgo);
    const formattedStartDate = startDateObj.toISOString().split('T')[0];
    const formattedEndDate = currentDate.toISOString().split('T')[0];

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);

    console.log(`Buscando marcadores entre ${formattedStartDate} e ${formattedEndDate}`);

    try {
      const response = await fetch(`${API_ENDPOINTS.places_group}/?start_date=${formattedStartDate}&end_date=${formattedEndDate}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Payload Recebido:", data);

        const formattedMarkers = data.map((item) => ({
          id: item.id,
          position: item.position.split(', ').map(Number),
          title: item.name,
          quanty_groups: item.quanty_groups,
          description: `Quantidade de grupos: ${item.quanty_groups}`
        }));

        console.log("Formatted Markers:", formattedMarkers);
        setMarkersData(formattedMarkers);
      } else {
        console.error('Erro ao buscar dados dos marcadores:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao buscar dados dos marcadores:', error);
    }
  };

  // Função para buscar ocorrências com base no código da localidade
  const fetchOccurrences = async (placeId) => {
    console.log('placeId recebido em fetchOccurrences:', placeId);
    const url = `${API_ENDPOINTS.occurrence}/?start_date=${startDate}&end_date=${endDate}&place=${placeId}`;
    console.log('URL da requisição:', url);
    setLoadingOccurrences(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        setOccurrences(data);
        console.log('Ocorrências recebidas:', data);
      } else {
        console.error('Erro ao buscar ocorrências:', response.statusText);
        setOccurrences([]);
      }
    } catch (error) {
      console.error('Erro ao buscar ocorrências:', error);
      setOccurrences([]);
    } finally {
      setLoadingOccurrences(false);
    }
  };

  // Hooks useEffect para buscar dados
  useEffect(() => {
    fetchCurrentDate();
  }, []);

  useEffect(() => {
    if (currentDate) {
      fetchMarkersData(8);
    }
  }, [currentDate]);

  // Handler para mudar o período selecionado
  const handlePeriodChange = (e) => {
    const period = e.target.value;
    console.log('Período selecionado:', period);
    setSelectedPeriod(period);

    const daysAgo = parseInt(period) + 1;
    console.log('Dias atrás para buscar dados dos marcadores:', daysAgo);
    fetchMarkersData(daysAgo);
  };

  // Função para mostrar o Drawer e buscar ocorrências
  const showDrawer = (item) => {
    console.log('Item recebido no showDrawer:', item);
    setDrawerContent(item);
    setVisible(true);
    fetchOccurrences(item.id);
  };

  // Função para fechar o Drawer
  const onClose = () => {
    setVisible(false);
    setDrawerContent(null);
    setOccurrences([]);
  };

  return (
    <div className='xxx'>
      <div className='full'>
        <div className='controls'>
          <Radio.Group defaultValue="7" buttonStyle="solid" onChange={handlePeriodChange}>
            <Radio.Button value="7">Últimos 7 dias</Radio.Button>
            <Radio.Button value="30">Últimos 30 dias</Radio.Button>
            <Radio.Button value="60">Últimos 60 dias</Radio.Button>
          </Radio.Group>
        </div>

        <MapComponent markersData={markersData} showDrawer={showDrawer} />

      </div>

      <div className='container'>
        <h2>Painel de Controle</h2>
        <Row gutter={24} style={{ marginTop: '16px' }}>
          <Col span={14}>
            <StreamChart data={data} />
          </Col>
          <Col span={10}>
            <Card>
              <CalendarComponent />
            </Card>
          </Col>
        </Row>

        <OccurrencesDrawer
          visible={visible}
          onClose={onClose}
          drawerContent={drawerContent}
          occurrences={occurrences}
          loadingOccurrences={loadingOccurrences}
        />
      </div>
    </div>
  );
};

export default Dashboard;
