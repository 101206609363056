import React from 'react';
import { Modal, Button } from 'antd';

const DeleteModal = ({
  visible,
  onCancel,
  apiUrl,
  itemId,
  itemName,
  onItemDeleted,
}) => {
  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiUrl}/${itemId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        onItemDeleted();
        onCancel();
      } else {
        console.error('Erro ao excluir item:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao excluir item:', error);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Confirmar Exclusão"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>Cancelar</Button>,
        <Button key="confirm" type="primary" danger onClick={handleDelete}>
          Excluir
        </Button>,
      ]}
    >
      <p>Tem certeza que deseja excluir {itemName}?</p>
    </Modal>
  );
};

export default DeleteModal;