import React, { useEffect } from 'react';
import { Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { API_ENDPOINTS } from '../../config/apiConfig';

const { confirm } = Modal;

const DeleteConfirmationModal = ({ visible, onCancel, reference, referenceType, handleReferenceUpdated }) => {
  const endpoints = {
    status: `${API_ENDPOINTS.status}/`, 
    places: `${API_ENDPOINTS.places}/`, 
    criticalities: `${API_ENDPOINTS.criticalities}/`,
    categories: `${API_ENDPOINTS.categories}/`,
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${endpoints[referenceType]}${reference.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        handleReferenceUpdated();
        onCancel();  // Fecha o modal após a exclusão
      } else {
        console.error('Erro ao excluir referência:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao excluir referência:', error);
    }
  };

  useEffect(() => {
    if (visible && reference) {
      confirm({
        title: 'Confirmação de Exclusão',
        icon: <ExclamationCircleOutlined />,
        content: `Tem certeza de que deseja excluir a referência "${reference?.name}"?`,
        okText: 'Excluir',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk: handleDelete,
        onCancel: onCancel,
      });
    }
  }, [visible, reference, handleDelete, onCancel]);

  return null;  // O modal é gerado pelo método confirm e não precisa ser renderizado diretamente
};

export default DeleteConfirmationModal;
