  import React, { useState, useEffect } from 'react';
  import { useParams, useNavigate } from 'react-router-dom'; // Importa useNavigate
  import { Button, Table, Space, Breadcrumb, Row, Col, Card } from 'antd';
  import AddReferenceModal from './AddReferenceModal';
  import EditReferenceModal from './EditReferenceModal';
  import DeleteConfirmationModal from './DeleteConfirmationModal';
  import { API_ENDPOINTS } from '../../config/apiConfig';

  const referenceMapping = {
    categories: {
      apiEndpoint: `${API_ENDPOINTS.categories}/`,
      name: 'Categoria',
      columns: [
        { title: 'Nome', dataIndex: 'name', key: 'name' },
        { title: 'Descrição', dataIndex: 'description', key: 'description' },
        { title: 'Situação', dataIndex: 'active', key: 'active' },
      ],
    },
    status: {
      apiEndpoint: `${API_ENDPOINTS.status}/`,
      name: 'Status',
      columns: [
        { title: 'Nome', dataIndex: 'name', key: 'name' },
        { title: 'Descrição', dataIndex: 'description', key: 'description' },
        { title: 'Situação', dataIndex: 'active', key: 'active' },
      ],
    },
    places: {
      apiEndpoint: `${API_ENDPOINTS.places}/`,
      name: 'Lugar',
      columns: [
        { title: 'Nome', dataIndex: 'name', key: 'name' },
        { title: 'Descrição', dataIndex: 'address', key: 'address' },
        { title: 'Situação', dataIndex: 'active', key: 'active' },
      ],
    },
    criticalities: {
      apiEndpoint: `${API_ENDPOINTS.criticalities}/`,
      name: 'Criticidade',
      columns: [
        { title: 'Nome', dataIndex: 'name', key: 'name' },
        { title: 'Descrição', dataIndex: 'description', key: 'description' },
        { title: 'Nível', dataIndex: 'level', key: 'level' },
      ],
    },
  };

  const ReferenceListPage = () => {
    const { type } = useParams();
    const navigate = useNavigate();
    const [references, setReferences] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedReference, setSelectedReference] = useState(null);

    const config = referenceMapping[type];

    useEffect(() => {
      if (config) {
        fetchReferences();
      }
    }, [type]);

    const fetchReferences = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(config.apiEndpoint, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (response.status === 403) {
          localStorage.removeItem('token')
          const data = await response.json();
          if (data.message === "Token expirado!") {
            navigate('/login');
          }
        } else if (response.ok) {
          const data = await response.json();
          setReferences(data);
        } else {
          console.error(`Erro ao buscar ${config.name.toLowerCase()}s:`, response.statusText);
        }
      } catch (error) {
        console.error(`Erro ao buscar ${config.name.toLowerCase()}s:`, error);
      }
    };

    const handleEditClick = (reference) => {
      setSelectedReference(reference);
      setShowEditModal(true);
    };

    const handleDeleteClick = (reference) => {
      setSelectedReference(reference);
      setShowDeleteModal(true);
    };

    const handleReferenceUpdated = () => {
      fetchReferences();
      setShowEditModal(false);
      setShowDeleteModal(false);
    };

    if (!config) {
      return <p>Tipo de referência inválido.</p>;
    }

    const columns = [
      ...config.columns,
      {
        title: 'Ações',
        key: 'actions',
        render: (text, record) => (
          <Space size="middle">
            <Button type="link" onClick={() => handleEditClick(record)}>
              Editar
            </Button>
            <Button type="link" danger onClick={() => handleDeleteClick(record)}>
              Excluir
            </Button>
          </Space>
        ),
      },
    ];

    return (
      <div className='container'>
        <Row justify="left" align="middle" style={{ padding: '10px' }}>
          <Breadcrumb style={{ marginBottom: 16 }}>
            <Breadcrumb.Item>Gestão</Breadcrumb.Item>
            <Breadcrumb.Item>Operação</Breadcrumb.Item>
            <Breadcrumb.Item>{config.name}</Breadcrumb.Item>
          </Breadcrumb>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Card>
              <Button type="primary" onClick={() => setShowAddModal(true)}>
                Adicionar {config.name}
              </Button>
              {references.length > 0 ? (
                <Table
                  columns={columns}
                  size="small"
                  dataSource={references}
                  rowKey={(record) => record.id}
                  style={{ marginTop: 20 }}
                />
              ) : (
                <p>Carregando {config.name.toLowerCase()}s...</p>
              )}
              <AddReferenceModal
                visible={showAddModal}
                onCancel={() => setShowAddModal(false)}
                reloadReferences={fetchReferences}
                referenceType={type}
              />
              <EditReferenceModal
                visible={showEditModal}
                onCancel={() => setShowEditModal(false)}
                reference={selectedReference}
                referenceType={type}
                handleReferenceUpdated={handleReferenceUpdated}
              />
              <DeleteConfirmationModal
                visible={showDeleteModal}
                onCancel={() => setShowDeleteModal(false)}
                reference={selectedReference}
                referenceType={type}
                handleReferenceUpdated={handleReferenceUpdated}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  };

  export default ReferenceListPage;
