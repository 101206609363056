// src/components/OccurrencesDrawer.js
import React from 'react';
import { Drawer, List, Avatar, Spin } from 'antd';
import { API_ENDPOINTS } from '../config/apiConfig';
import { Link } from 'react-router-dom';

const OccurrencesDrawer = ({
  visible,
  onClose,
  drawerContent,
  occurrences,
  loadingOccurrences,
}) => {
  return (
    <Drawer
      title={drawerContent ? drawerContent.title : 'Detalhes'}
      placement="right"
      onClose={onClose}
      visible={visible}
      mask={false}
      width={600} // Ajuste a largura conforme necessário
    >
      {loadingOccurrences ? (
        <div style={{ textAlign: 'center', paddingTop: '50px' }}>
          <Spin tip="Carregando..." />
        </div>
      ) : occurrences.length > 0 ? (
        <List
          itemLayout="vertical"
          size="large"
          pagination={{
            onChange: (page) => {
              console.log('Página:', page);
            },
            pageSize: 100,
          }}
          dataSource={occurrences}
          renderItem={(occurrence) => {
            // Formatação da data aqui, dentro do renderItem
            const formattedDate = new Date(occurrence.created_at).toLocaleDateString('pt-BR');
            return (
              <List.Item
                key={occurrence.group_id}
                extra={
                  occurrence.images && occurrence.images.length > 0 ? (
                    <div className="img">
                      <img
                        width={80}
                        alt="imagem"
                        src={`${API_ENDPOINTS.images}/thumb/${occurrence.images[0]}`}
                      />
                    </div>
                  ) : null
                }
              >
                {/* Envolvendo o conteúdo com o Link */}
                <Link to={`/occurrences/detail/${occurrence.group_id}`} style={{ display: 'block', width: '100%' }}>
                  <List.Item.Meta
                    avatar={<Avatar src={`${API_ENDPOINTS.images}/thumb/${occurrence.company.image}`} />}
                    title={
                      <div>
                        {occurrence.company.name}
                        <span> - {formattedDate}</span>
                      </div>
                    }
                    description={
                      <p>
                        {[
                          occurrence.composition.audios > 0 && `${occurrence.composition.audios} ${occurrence.composition.audios > 1 ? 'áudios' : 'áudio'}`,
                          occurrence.composition.images > 0 && `${occurrence.composition.images} ${occurrence.composition.images > 1 ? 'imagens' : 'imagem'}`,
                          occurrence.composition.messages > 0 && `${occurrence.composition.messages} ${occurrence.composition.messages > 1 ? 'mensagens' : 'mensagem'}`,
                          occurrence.composition.videos > 0 && `${occurrence.composition.videos} ${occurrence.composition.videos > 1 ? 'vídeos' : 'vídeo'}`
                        ].filter(Boolean).join(' e ')}
                      </p>
                    }
                  />
                </Link>
              </List.Item>
            );
          }}
        />
      ) : (
        <p>Nenhuma ocorrência encontrada.</p>
      )}
    </Drawer>
  );
};

export default OccurrencesDrawer;
