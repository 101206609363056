import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Select, message, Tag } from 'antd';
import { API_ENDPOINTS } from '../../../config/apiConfig';


const { Option } = Select;

const ReferencesManager = ({ id, categories, places, statuses, onReferencesChange }) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);

    useEffect(() => {
        fetchGroupReferences();
    }, [id]);

    const fetchGroupReferences = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_ENDPOINTS.group_references}/${id}/1`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = response.data;
            setSelectedCategory(data.categories[0]?.id);
            setSelectedPlace(data.places[0]?.id);
            setSelectedStatus(data.status[0]?.id);
        } catch (error) {
            console.error('Error fetching group references:', error);
        }
    };

    const handleChange = async (value, type) => {
        const token = localStorage.getItem('token');

        const previousValue = type === 'categories' ? selectedCategory :
            type === 'places' ? selectedPlace :
                selectedStatus;

        if (previousValue) {
            await removeReference(previousValue, type, token);
        }

        if (value && value.length > 0) {
            await addReference(value[0], type, token);
        }

        if (type === 'categories') setSelectedCategory(value);
        if (type === 'places') setSelectedPlace(value);
        if (type === 'status') setSelectedStatus(value);

        onReferencesChange(); // Notify about the change
    };

    const removeReference = async (referenceId, type, token) => {
        try {
            await axios.delete(`${API_ENDPOINTS.group_references}/references/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    'reference_type': type,
                    'reference_id': referenceId
                }
            });
            message.success(`Referência de ${type} removida com sucesso!`);
        } catch (error) {
            console.error(`Error deleting ${type} reference:`, error);
            message.error(`Erro ao remover referência de ${type}.`);
        }
    };

    const addReference = async (referenceId, type, token) => {
        try {
            const data = {
                'core_group_id': id,
                'company_id': 1,
                'reference_type': type,
                'reference_id': referenceId
            };
            await axios.post(`${API_ENDPOINTS.group_references}/references`, JSON.stringify(data), {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            message.success(`Referência de ${type} adicionada com sucesso!`);
        } catch (error) {
            console.error(`Error adding ${type} reference:`, error);
            message.error(`Erro ao adicionar referência de ${type}.`);
        }
    };

    const tagRender = (props) => {
        const { label, value, closable, onClose } = props;
        const color = statuses.find(status => status.id === value)?.description || 'default';
        return (
            <Tag
                color={color}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    };

    return (
        <div className='ReferencesManager'>
            <div className='box'>
                <label>Categorias:</label>
                <Select
                    mode="multiple"
                    maxCount={1}
                    placeholder="Selecione uma Categoria"
                    value={selectedCategory}
                    onChange={(value) => handleChange(value, 'categories')}
                >
                    {categories.map(category => (
                        <Option key={category.id} value={category.id}>
                            {category.name}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className='box'>
                <label>Localidade:</label>
                <Select
                    mode="multiple"
                    maxCount={1}
                    placeholder="Selecione um Local"
                    value={selectedPlace}
                    onChange={(value) => handleChange(value, 'places')}
                >
                    {places.map(place => (
                        <Option key={place.id} value={place.id}>
                            {place.name}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className='box'>
                <label>Status:</label>
                <Select
                    mode="multiple"
                    maxCount={1}
                    placeholder="Selecione um Status"
                    value={selectedStatus}
                    onChange={(value) => handleChange(value, 'status')}
                    tagRender={tagRender}
                >
                    {statuses.map(status => (
                        <Option key={status.id} value={status.id}>
                            {status.name}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    );
};

export default ReferencesManager;
